import { BasicGridFilter, BasicGridTransformedFilters } from '../../../@types/grid';

export const getFilters = (filters: BasicGridTransformedFilters): BasicGridFilter[] => [
  {
    type: 'name',
    displayName: 'Name',
    value: filters?.name || '',
    icon: 'fe:globe'
  },
  {
    type: 'id',
    displayName: 'Id',
    value: filters?.id || '',
    icon: 'fluent:key-16-regular'
  }
];
