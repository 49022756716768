const getConfig = (reactAppDeployEnv?: string) => {
    if (reactAppDeployEnv === "production") {
        return {
            SERVICES_ADMIN_URL: "https://services-admin.elton.app",
            STATIONS_URL: "https://stations.elton.app",
            BUSTER_URL: "https://buster.elton.app",
            KINDE: {
                CLIENT_ID: "872cdc774c7a4c95becbecfdd02dbb15",
                DOMAIN: "https://auth.elton.app",
                REDIRECT_URI: "https://admin.elton.app/login/callback",
                LOGOUT_URI: "https://admin.elton.app/logout",
            },
            LOYALTY: "https://loyalty.prod-gcp.elton.app"
        };
    } else if (reactAppDeployEnv === "staging") {
        return {
            SERVICES_ADMIN_URL: "https://services-admin.staging.elton.app",
            STATIONS_URL: "https://stations.staging.elton.app",
            BUSTER_URL: "https://buster.staging.elton.app",
            KINDE: {
                CLIENT_ID: "e64a26f62d66465597f05b1fca54a320",
                DOMAIN: "https://elton-development.eu.kinde.com",
                REDIRECT_URI: "https://admin.staging.elton.app/login/callback",
                LOGOUT_URI: "https://admin.staging.elton.app/logout",
            },
            LOYALTY: "https://loyalty.staging-gcp.elton.app"
        };
    } else {
        // change these if you need different values for local development
        return {
            SERVICES_ADMIN_URL: "https://services-admin.staging.elton.app", // http://localhost:4000
            STATIONS_URL: "https://stations.staging.elton.app", // localhost:8080
            BUSTER_URL: "https://buster.staging.elton.app",
            KINDE: {
                CLIENT_ID: "e64a26f62d66465597f05b1fca54a320",
                DOMAIN: "https://elton-development.eu.kinde.com",
                REDIRECT_URI: "http://localhost:3001/login/callback",
                LOGOUT_URI: "http://localhost:3001/logout",
            },
            LOYALTY: "http://localhost:8080"
        };
    }
};

export const CONFIG: {
    SERVICES_ADMIN_URL: string;
    BUSTER_URL: string;
    KINDE: {
        DOMAIN: string;
        LOGOUT_URI: string;
        CLIENT_ID: string;
        REDIRECT_URI: string;
    };
    STATIONS_URL: string;
    LOYALTY: string;
} = getConfig(process.env.REACT_APP_DEPLOY_ENV);
