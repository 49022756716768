import { styled } from '@material-ui/core/styles';
import FlightIcon from '@mui/icons-material/Flight';

const EasterEggContainer = styled('div')(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100vw',
  height: '100vh'
}));

const PlaneIcon = styled(FlightIcon)(({ theme }) => ({
  position: 'absolute',
  bottom: '0',
  left: '0',
  width: '20rem',
  height: '20rem',
  color: '#003251',
  '@keyframes straightLine': {
    '25%': {
      bottom: '500px',
      left: '0',
      color: '#d81939'
    },
    '50%': {
      bottom: '500px',
      left: '800px',
      color: '#d81939'
    },
    '75%': {
      bottom: '0px',
      left: '800px',
      color: '#d81939'
    },
    '100%': {
      transform: 'rotate(365deg)',
      color: '#003251'
    }
  },
  animation: 'straightLine 5s infinite linear'
}));

/**
 *Funny easter egg plane made on the way (ish) to Spain
 */
const Plane = ({ show }: any) => (
  <>
    {show && (
      <EasterEggContainer>
        <PlaneIcon />
      </EasterEggContainer>
    )}
  </>
);

export { Plane };
