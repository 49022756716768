import { Container } from '@material-ui/core';
import Page from 'components/Page';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import { PATH_DASHBOARD } from '../../routes/paths';
import Grid from '../../components/b2b/plans';

export default function Plans() {
  return (
    <Page title="B2B - Plan">
      <Container maxWidth="xl">
        <HeaderBreadcrumbs
          heading={`Plans`}
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            { name: 'Plans', href: PATH_DASHBOARD.b2b.plans }
          ]}
        />
        <Grid />
      </Container>
    </Page>
  );
}
