import axios from "../../utils/axios";
import {CONFIG} from "../../config";

export async function getBackofficeUser(email: string) {
    const res = await axios.get(`${CONFIG.SERVICES_ADMIN_URL}/api/v1/backoffice/user?email=${email}`)
    return res.data as BackofficeUser
}

export interface BackofficeUser {
    /**
     * uuid
     */
    primary_id: string
    email: string
    first_name: string
    last_name: string
    roles: BackofficeRoles[]
}

export interface BackofficeRoles {
    /**
     * uuid
     */
    primary_id: string
    /**
     * Only interested in 'admin_dashboard' in this project
     */
    role_scope: BackofficeRoleScope
    /**
     * Only interested in 'editor' and 'viewer' in this project
     */
    role_name: BackofficeRoleName
}

export enum BackofficeRoleName {
    SUPERADMIN = 'superadmin',
    USER = 'user',
    EDITOR = 'editor',
    VIEWER = 'viewer',
}

export enum BackofficeRoleScope {
    B2B_PORTAL = 'b2b_portal',
    ADMIN_DASHBOARD = 'admin_dashboard',
}
