// material
import { Container } from '@material-ui/core';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
// components
import Page from '../../components/Page';
import Edit from '../../components/subscriptions/contracts/editContract';
import { UserContext } from '../../contexts/UserContext';
// hooks
import useSettings from '../../hooks/useSettings';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';

// ----------------------------------------------------------------------

export default function ContractEdit() {
  const { themeStretch } = useSettings();

  // Get locationId from path
  const params: any = useParams();
  const [id, setId] = useState<string>(params?.id);
  const [{ user }]: any = React.useContext(UserContext);

  return (
    <Page title="Edit Subscription Contract">
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <HeaderBreadcrumbs
          heading="View Subscription Contract"
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            { name: 'Subscriptions', href: PATH_DASHBOARD.subscriptions.offers },
            { name: 'Contracts', href: PATH_DASHBOARD.subscriptions.contracts }
          ]}
        />
        <Edit id={id} setId={(id: string) => setId(id)} disabled={!(id && user.canEdit)} />
      </Container>
    </Page>
  );
}
