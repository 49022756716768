import { Icon } from '@iconify/react';
import { FormControl, MenuItem, Select, styled } from '@material-ui/core';
import ClearIcon from '@mui/icons-material/Clear';
import { transformAmount } from '../../../../../../utils/currency';

const StyledIcon = styled(Icon)({
  marginRight: '0.6rem'
});

const Action = ({ id, deleteCallback, canEdit }: any) => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      height: '100%'
    }}
  >
    {id && canEdit ? (
      <ClearIcon style={{ cursor: 'pointer' }} color="action" onClick={() => deleteCallback(id)} />
    ) : (
      ''
    )}
  </div>
);

const findPriceUnit = (params: any, operator: any, type: string) => {
  const currency = params?.row?.currency_override || operator?.country?.currency || 'NOK';
  const basePrice = transformAmount(params.value || 0, currency, true);
  if (params.value) {
    if (type === 'ENERGY') {
      return `${params.value}/kWh`;
    }
    if (type === 'TIME' || type === 'PARKING_TIME') {
      return `${params.value} ${currency}`;
    } else if (type === 'STARTUP') {
      return params.value.toString();
    }
  }
  return basePrice;
};

const findStepSizeUnit = (params: any, type: string) => {
  if (params.value) {
    if (type === 'ENERGY') {
      return `${params.value} Wh`;
    }
    if (type === 'TIME' || type === 'PARKING_TIME') {
      if (params.value >= 60 * 60) {
        return `${(params.value / (60 * 60)).toFixed(2)} h`;
      }
      if (params.value >= 60) {
        return `${(params.value / 60).toFixed(2)} min`;
      }
      return `${params.value} sec`;
    }
  }
  return 0;
};

const findSurcharge = (params: any, operator: any) => {
  if (params.value) {
    return `${params.value}%`;
  }
  return operator.surcharge ? `${operator.surcharge}%*` : `0%*`;
};

const typeSelector = (params: any) => {
  const { id, value, api, field } = params;
  const handleChange = async (e: any) => {
    api.setEditCellValue({ id, field, value: e.target.value });
    const isValid = await api.commitCellChange({ id, field });
    if (isValid) {
      api.setCellMode(id, field, 'view');
    }
  };
  return (
    <FormControl fullWidth>
      <Select open={true} value={value} onChange={handleChange}>
        <MenuItem value="ENERGY">
          <StyledIcon icon="bi:lightning-charge" />
          Energy
        </MenuItem>
        <MenuItem value="FLAT">
          <StyledIcon icon="codicon:symbol-constant" />
          Flat
        </MenuItem>
        <MenuItem value="PARKING_TIME">
          <StyledIcon icon="fa-solid:parking" />
          Parking time
        </MenuItem>
        <MenuItem value="TIME">
          <StyledIcon icon="bx:bx-time" />
          Time
        </MenuItem>
        <MenuItem value="NONE">None</MenuItem>
      </Select>
    </FormControl>
  );
};

const getType = (params: any) => {
  if (params.value === 'ENERGY') {
    return (
      <>
        <StyledIcon icon="bi:lightning-charge" />
        Energy
      </>
    );
  }
  if (params.value === 'FLAT') {
    return (
      <>
        <StyledIcon icon="codicon:symbol-constant" />
        Flat
      </>
    );
  }
  if (params.value === 'PARKING_TIME') {
    return (
      <>
        <StyledIcon icon="fa-solid:parking" />
        Parking time
      </>
    );
  }
  if (params.value === 'TIME') {
    return (
      <>
        <StyledIcon icon="bx:bx-time" />
        Time
      </>
    );
  }
  if (params.value === 'NONE') {
    return 'None';
  }
  return params.value;
};

export const getPriceColumns = (operator: any) => [
  {
    field: 'actions',
    headerName: ' ',
    description: 'Actions',
    width: 40,
    sortable: false,
    renderCell: (params: any) => Action(params.value)
  },
  {
    field: 'external_index',
    headerName: 'External index',
    description: `Value 0 overrides other values, never deleted, lowest match is chosen. Not possible to use several tariffs if 0 is used. `,
    width: 170,
    editable: true,
    type: 'number',
    renderCell: (params: any) =>
      params?.value === 0 || params?.value ? parseInt(params?.value) : ''
  },
  {
    field: 'startup_price',
    headerName: 'Startup price',
    description: 'Starting fee',
    width: 140,
    editable: true,
    type: 'number',
    renderCell: (params: any) => findPriceUnit(params, operator, 'STARTUP')
  },

  {
    field: 'energy_price',
    headerName: 'Energy price',
    description: 'kWh price',
    width: 140,
    editable: true,
    type: 'number',
    renderCell: (params: any) => findPriceUnit(params, operator, 'ENERGY')
  },
  {
    field: 'energy_step_size',
    headerName: 'Energy step size',
    description:
      'Should be 1 to represent 1 Wh. Others use 1000kWh but this is transformed on import to 1.',
    width: 160,
    editable: true,
    type: 'number',
    renderCell: (params: any) => findStepSizeUnit(params, 'ENERGY')
  },
  {
    field: 'time_price',
    headerName: 'Time price',
    description: 'Time price',
    width: 140,
    editable: true,
    type: 'number',
    renderCell: (params: any) => findPriceUnit(params, operator, 'TIME')
  },
  {
    field: 'time_step_size',
    headerName: 'Time step size',
    description: '60 represents per minute price, 3600 is hourly',
    width: 160,
    editable: true,
    type: 'number',
    renderCell: (params: any) => findStepSizeUnit(params, 'TIME')
  },
  {
    field: 'parking_price',
    headerName: 'Parking price',
    description: 'Parking price',
    width: 140,
    editable: true,
    type: 'number',
    renderCell: (params: any) => findPriceUnit(params, operator, 'PARKING_TIME')
  },
  {
    field: 'parking_step_size',
    headerName: 'Parking step size',
    description: '60 represents per minute price, 3600 is hourly',
    width: 160,
    editable: true,
    type: 'number',
    renderCell: (params: any) => findStepSizeUnit(params, 'PARKING_TIME')
  },

  {
    field: 'day_of_week',
    headerName: 'Day of week',
    description:
      'Day of weeks that the price is applicable. Days should be given in capital letters with comma seperation in English.' +
      'E.g. MONDAY, TUESDAY, WEDNESDAY',
    width: 300,
    editable: true,
    type: 'string'
  },
  {
    field: 'restrictions',
    headerName: 'Restrictions',
    description:
      'This row shows all restrictions, and if edited data should be given as json. Shortcut solution, ask backend team for assistance.',
    width: 600,
    editable: true,
    type: 'string',
    renderCell: (params: any) => (params.value ? JSON.stringify(params.value) : '')
  },
  {
    field: 'created_at',
    headerName: 'Created At',
    width: 200,
    renderCell: (params: any) => (params.value ? params.value.toLocaleString() : '')
  },
  {
    field: 'updated_at',
    headerName: 'Updated at',
    width: 200,
    renderCell: (params: any) => (params.value ? params.value.toLocaleString() : '')
  },
  {
    field: 'primary_id',
    headerName: 'Id',
    description: '',
    width: 350
  }
];
