import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import { getContracts } from 'api/elton-payments/subscriptions/contracts';
import BasicGrid from 'components/grid/basic-grid';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ContractsProps } from '../../../@types/subscriptions/contract';
import { UserContext } from '../../../contexts/UserContext';
import { gridColumns } from './gridColumns';
import { getFilters } from './gridFilters';

const CreateButton = () => {
  const navigate = useNavigate();

  const handleClick = async () => {
    navigate(`/dashboard/subscriptions/contracts/new`);
  };

  return (
    <Button onClick={handleClick} style={{ width: '3.5rem', height: '3.5rem' }} variant="outlined">
      <AddIcon />
    </Button>
  );
};

export const Grid = (props: ContractsProps) => {
  const [{ user }]: any = React.useContext(UserContext);

  // Add feilds that is only used by the table
  const mapContracts = async (...args: any) => {
    const result = await getContracts(...args);
    result.data.data = result.data.data.map((contract: any) => ({
      ...contract,
      actions: contract,
      firebase_id: contract?.user?.uid,
      subCountry: contract?.subscription ? contract?.subscription.country : null,
      subName: contract?.subscription ? contract?.subscription.name : null,
      subKwhLimit: contract?.subscription ? contract?.subscription.kwh_limit : null
    }));
    return result;
  };

  return (
    <BasicGrid
      RightNavbarItem={user.canEdit && CreateButton}
      fetchFunction={mapContracts}
      columns={gridColumns}
      pages={props.pages}
      filters={getFilters(props.filters)}
      order={props.order}
      onUpdate={props.onUpdate}
    />
  );
};
