import { GridSortModel } from '@mui/x-data-grid';
import { BasicGridFilter, BasicGridTransformedFilters } from '../@types/grid';

/**
 * Transforms internal filters to request friendly filters
 * @param filters
 * @returns
 */
export const transformFilters = (filters: BasicGridFilter[]): BasicGridTransformedFilters => {
  const returnFilter: BasicGridTransformedFilters = {};
  for (const filter of filters) {
    returnFilter[filter.type] = filter.value;
  }
  return returnFilter;
};

/**
 * Transforms internal orders to request friendly order
 * @param orders
 * @returns
 */
export const transformOrder = (orders: GridSortModel): string | null => {
  if (orders.length > 0 && orders[0]) {
    return orders[0].sort === 'desc' ? `-${orders[0].field}` : orders[0].field;
  }
  return null;
};

/**
 * Get internal order from external format
 * @param order
 * @returns
 */
export const getOrder = (order: string | null | undefined): GridSortModel => {
  if (order && order.length > 0) {
    if (order.charAt(0) === '-') {
      return [{ field: order.substring(1), sort: 'desc' }];
    }
    return [{ field: order, sort: 'asc' }];
  }
  // default order by newest
  return [{ field: 'createdAt', sort: 'desc' }];
};
