import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import BasicGrid from 'components/grid/basic-grid';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ContractsProps } from '../../../@types/subscriptions/contract';
import {
  createCountryv2,
  deleteCountryv2,
  getCountriesv2
} from '../../../api/elton-payments/country';
import { UserContext } from '../../../contexts/UserContext';
import DeleteDialog from './deleteDialog';
import { gridColumns } from './gridColumns';
import { getFilters } from './gridFilters';
import {
  createCurrency,
  deleteCurrency,
  getCurrencies
} from '../../../api/elton-payments/currency';

const CreateButton = () => {
  const navigate = useNavigate();

  const handleClick = async () => {
    try {
      navigate(`/dashboard/currencies/edit`);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Button onClick={handleClick} style={{ width: '3.5rem', height: '3.5rem' }} variant="outlined">
      <AddIcon />
    </Button>
  );
};

export const Grid = (props: ContractsProps) => {
  // Delete modal
  const [deleteEntry, setDeleteEntry] = useState<any>({ open: false, id: null });

  // Val to handle grid refresh
  const [forceUpdate, setForceUpdate] = useState(0);
  const [{ user }]: any = React.useContext(UserContext);

  const handleDelete = async () => {
    try {
      await deleteCurrency(deleteEntry?.id);
      setForceUpdate(forceUpdate + 1);
      setDeleteEntry({ open: false, id: null });
    } catch (e) {
      console.error(e);
    }
  };

  // Add fields that are only used by the table
  const mapCurrencies = async (...args: any) => {
    const result = await getCurrencies(...args);

    result.data.data = result.data.data.map((c: any) => ({
      id: c.primary_id,
      ...c,
      actions: {
        id: c.primary_id,
        deleteCallback: (id: string) => setDeleteEntry({ open: true, id }),
        canEdit: user.canEdit
      }
    }));
    return result;
  };

  return (
    <>
      <BasicGrid
        fetchFunction={mapCurrencies}
        columns={gridColumns}
        pages={props.pages}
        filters={getFilters(props.filters)}
        order={props.order}
        onUpdate={props.onUpdate}
        RightNavbarItem={user.canEdit && CreateButton}
        forceUpdate={forceUpdate}
      />
      <DeleteDialog
        handleClose={() => setDeleteEntry({ open: false, id: null })}
        open={deleteEntry.open}
        handleDelete={handleDelete}
      />
    </>
  );
};
