import * as currencies from '@dinero.js/currencies';
import {
  Box,
  Card,
  Chip,
  Container,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
// material
import { styled } from '@material-ui/core/styles';
import { dinero, toFormat } from 'dinero.js';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getSession } from '../../api/elton-payments/charging/transactions';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
// components
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import { DinteroStatusCodes } from '../../components/subscriptions/transactions/constants';
// hooks
import useSettings from '../../hooks/useSettings';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
import { transformer } from '../../utils/currency';
// ----------------------------------------------------------------------

const RowResultStyle = styled(TableRow)(({ theme }) => ({
  '& td': {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  }
}));

const Status = ({ status: state }: { status: string }) => {
  if (state) {
    let status: any = {
      displayValue: state,
      color: 'error',
      variant: 'outlined'
    };
    if (Object.keys(DinteroStatusCodes).includes(status.displayValue)) {
      status = DinteroStatusCodes[status.displayValue];
    }
    return (
      <Chip
        style={{ width: '100%', marginTop: '1.5rem', marginBottom: '1.5rem' }}
        label={status.displayValue}
        color={status.color}
        variant={status.variant}
      />
    );
  }
  return <></>;
};

const LocationDetails = ({ session }: any) => {
  const location: any = { name: '', address: '', operator: '', city: '', evseId: '' };
  if (session?.cdr) {
    const ocpiLocation = session?.cdr?.connector?.evse?.location;
    location.evseId = session?.cdr?.connector?.evse?.evse_id;
    location.name = ocpiLocation?.name;
    location.address = ocpiLocation?.address;
    location.operator = ocpiLocation?.operator?.name;
    location.city = ocpiLocation?.city;
    location.postalCode = ocpiLocation?.postal_code;
  }
  if (session?.oicp_cdr) {
    const oicpEvse = session?.oicp_cdr?.evse;
    location.name = oicpEvse?.charging_station_names.find((f: any) => f.value)?.value;
    location.evseId = oicpEvse?.evse_id;
    location.address = oicpEvse?.address?.street;
    location.operator = oicpEvse?.operator_name;
    location.city = oicpEvse?.address?.city;
    location.postalCode = oicpEvse?.address?.postal_code;
  }
  return (
    <>
      <Typography paragraph variant="overline" sx={{ color: 'text.disabled' }}>
        Station details
      </Typography>
      <Typography variant="subtitle2" style={{ marginBottom: '0.4rem' }}>
        {location.operator}
      </Typography>
      <Typography style={{ marginBottom: '0.4rem' }} variant="subtitle2">
        {location.evseId}
      </Typography>
      <Typography variant="body2">{location.name}</Typography>
      <Typography variant="body2">{location.address}</Typography>
      <Typography variant="body2">
        {location.postalCode} {location.city}
      </Typography>
    </>
  );
};

const PriceRow = ({ session, index, type }: any) => {
  const priceRow: any = { name: type, qty: 0, price: 0, total: 0, surcharge: 0 };
  if (session) {
    const ocpiCdr = session?.cdr;
    const oicpCdr = session?.oicp_cdr;
    if (type === 'Energy') {
      if (ocpiCdr) {
        priceRow.qty = ocpiCdr.total_energy;
      }
      if (oicpCdr) {
        priceRow.qty = oicpCdr.consumed_energy;
      }
      priceRow.price = Math.round(session.kwh_price * 100 + Number.EPSILON);
    } else {
      if (ocpiCdr) {
        priceRow.qty = Math.round(ocpiCdr.total_time * 60 * 100) / 100;
      }
      if (oicpCdr) {
        const start = new Date(oicpCdr.session_start);
        const end = new Date(oicpCdr.session_end);
        const diff = end.getTime() - start.getTime();
        priceRow.qty = Math.round((diff / 1000 / 60) * 100 + Number.EPSILON) / 100;
      }
      priceRow.price = Math.round(session.minute_price * 100 + Number.EPSILON);
    }
    priceRow.surcharge = (session?.surcharge - 1) * 100;
    priceRow.total = Math.round(
      priceRow.qty * priceRow.price * session?.surcharge + Number.EPSILON
    );
  }
  return (
    <>
      <TableRow
        key={index}
        sx={{
          borderBottom: (theme) => `solid 1px ${theme.palette.divider}`
        }}
      >
        <TableCell>{index}</TableCell>
        <TableCell align="left">
          <Box sx={{ maxWidth: 560 }}>
            <Typography variant="subtitle2">{type}</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
              (Qty reported by operator)
            </Typography>
          </Box>
        </TableCell>
        <TableCell align="left">
          <Typography variant="subtitle2">
            {priceRow?.qty} {type === 'Energy' ? 'kWh' : 'min'}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Currency value={priceRow?.price || 0} currency={session?.currency} />
          {' /'}
          {type === 'Energy' ? 'kWh' : 'min'}
        </TableCell>
        <TableCell align="right">{priceRow?.surcharge}%</TableCell>
        <TableCell align="right">
          <Currency value={priceRow?.total || 0} currency={session?.currency} />
        </TableCell>
      </TableRow>
    </>
  );
};

const Currency = ({ value, currency }: any) => {
  const dinteroValue = dinero({
    amount: value,
    currency: (currencies as any)[Object.keys(currencies).includes(currency) ? currency : 'NOK']
  });
  return <>{value || value === 0 ? `${toFormat(dinteroValue, transformer)}` : ''}</>;
};

// ----------------------------------------------------------------------

export default function ViewSession() {
  const { themeStretch } = useSettings();

  const [session, setSession] = useState<any>();
  const params: any = useParams();
  const id = params?.id;

  useEffect(() => {
    (async () => {
      const result = await getSession(id);
      setSession(result.data);
    })();

  }, []);

  const formatDate = (date: string) => {
    if (date) {
      const dateObject = new Date(date);
      return dateObject.toLocaleString();
    }
    return '';
  };

  return (
    <Page title="Session details">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="Session Details"
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            {
              name: 'Sessions',
              href: PATH_DASHBOARD.charging.sessions
            },
            { name: 'Details' }
          ]}
        />

        <Card sx={{ pt: 5, px: 5 }}>
          <Grid container>
            <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
              <Box
                component="img"
                alt="logo"
                src="/static/brand/logo_single.svg"
                sx={{ height: 48 }}
              />
            </Grid>

            <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
              <Box sx={{ textAlign: { sm: 'right' } }}>
                <Typography
                  sx={{ color: 'text.disabled' }}
                  style={{ marginTop: '0.5rem' }}
                  variant="subtitle2"
                >
                  Protocol:
                </Typography>
                <Typography sx={{ color: 'text.disabled' }} variant="body2">
                  {session?.protocol}
                </Typography>
                <Typography
                  sx={{ color: 'text.disabled' }}
                  style={{ marginTop: '0.5rem' }}
                  variant="subtitle2"
                >
                  Session Id:
                </Typography>
                <Typography sx={{ color: 'text.disabled' }} variant="body2">
                  {id}
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
              <LocationDetails session={session} />
            </Grid>

            <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
              <Typography paragraph variant="overline" sx={{ color: 'text.disabled' }}>
                Paid by
              </Typography>
              {session?.b2b && (
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography variant="body2">Company name:</Typography>
                  <Typography variant="body2">
                    {session?.company.find((f: any) => f?.name).name}
                  </Typography>
                </div>
              )}
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="body2">User FirebaseId:</Typography>
                <Typography variant="body2">{session?.user?.uid}</Typography>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="body2">User Id:</Typography>
                <Typography variant="body2">{session?.user?.id}</Typography>
              </div>
              <Typography
                style={{ marginTop: '1rem' }}
                paragraph
                variant="overline"
                sx={{ color: 'text.disabled' }}
              >
                Time details
              </Typography>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="body2">Start time:</Typography>
                <Typography variant="body2">
                  {formatDate(session?.cdr?.start_date_time || session?.oicp_cdr?.session_start)}
                </Typography>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="body2">End time:</Typography>
                <Typography variant="body2">
                  {formatDate(session?.cdr?.stop_date_time || session?.oicp_cdr?.session_end)}
                </Typography>
              </div>
            </Grid>
          </Grid>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 960 }}>
              <Table>
                <TableHead
                  sx={{
                    borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
                    '& th': { backgroundColor: 'transparent' }
                  }}
                >
                  <TableRow>
                    <TableCell width={40}>#</TableCell>
                    <TableCell align="left">Description</TableCell>
                    <TableCell align="left">Qty</TableCell>
                    <TableCell width={170} align="left">
                      Unit price
                    </TableCell>
                    <TableCell align="right">Surcharge</TableCell>
                    <TableCell align="right">Total</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  <PriceRow key={1} session={session} index={1} type="Energy" />
                  <PriceRow key={2} session={session} index={2} type="Time" />
                  <RowResultStyle>
                    <TableCell colSpan={4} />
                    <TableCell align="right">
                      <Box sx={{ mt: 2 }} />
                      <Typography variant="body1">Subtotal</Typography>
                    </TableCell>
                    <TableCell align="right" width={190}>
                      <Box sx={{ mt: 2 }} />
                      <Typography variant="body1">
                        {' '}
                        <Currency
                          value={
                            Math.round(session?.total_cost / session?.vat + Number.EPSILON) || 0
                          }
                          currency={session?.currency}
                        />
                      </Typography>
                    </TableCell>
                  </RowResultStyle>
                  {/* <RowResultStyle>
                    <TableCell colSpan={3} />
                    <TableCell align="right">
                      <Typography variant="body1">Discount</Typography>
                    </TableCell>
                    <TableCell align="right" width={120}>
                      <Typography sx={{ color: 'error.main' }}>
                        {fCurrency(-INVOICE.discount)}
                      </Typography>
                    </TableCell>
                  </RowResultStyle> */}
                  <RowResultStyle>
                    <TableCell colSpan={4} />
                    <TableCell align="right">
                      <Typography variant="body1">VAT</Typography>
                    </TableCell>
                    <TableCell align="right" width={190}>
                      <Typography variant="body1">
                        <Currency
                          value={
                            Math.round(
                              (session?.total_cost / session?.vat) * (session?.vat - 1) +
                                Number.EPSILON
                            ) || 0
                          }
                          currency={session?.currency}
                        />
                      </Typography>
                    </TableCell>
                  </RowResultStyle>
                  <RowResultStyle>
                    <TableCell colSpan={4} />
                    <TableCell align="right">
                      <Typography variant="subtitle1">Total</Typography>
                    </TableCell>
                    <TableCell align="right" width={190}>
                      <Typography variant="subtitle1">
                        <Currency value={session?.total_cost || 0} currency={session?.currency} />
                      </Typography>
                    </TableCell>
                  </RowResultStyle>
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
          <Grid container>
            <Grid style={{ margin: 0 }} item xs={12} sm={12} sx={{ mb: 12 }}>
              <Status status={session?.status} />
            </Grid>
          </Grid>
          {/* <Grid container>
            <Grid item xs={12} md={9} sx={{ py: 3 }}>
              <Typography variant="subtitle2">NOTES</Typography>
              <Typography variant="body2">
                We appreciate your business. Should you need us to add VAT or extra notes let us
                know!
              </Typography>
            </Grid>
            <Grid item xs={12} md={3} sx={{ py: 3, textAlign: 'right' }}>
              <Typography variant="subtitle2">Have a Question?</Typography>
              <Typography variant="body2">support@minimals.cc</Typography>
            </Grid>
          </Grid> */}
        </Card>
      </Container>
    </Page>
  );
}
