import { Icon } from '@iconify/react';
import { Link, Stack, Typography } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import * as React from 'react';

const IconStyle = styled(Icon)(({ theme }) => ({
  width: 20,
  height: 20,
  marginTop: 1,
  flexShrink: 0,
  marginRight: theme.spacing(2)
}));

const MAP_FIELD_TO_ICON: any = {
  default: 'bytesize:code'
};

const changeElement = (element: any, index: number, iconOverride: string = '') => (
  <Stack spacing={1} sx={{ p: 0.5 }} key={index}>
    <Stack direction="row">
      <IconStyle
        icon={
          iconOverride
            ? MAP_FIELD_TO_ICON[iconOverride]
            : MAP_FIELD_TO_ICON[
                Object.keys(MAP_FIELD_TO_ICON).includes(element?.field) ? element?.field : 'default'
              ]
        }
      />
      <Typography variant="body2">
        {element?.displayName || element.field.charAt(0).toUpperCase() + element.field.substring(1)}
        :
        <Link component="span" variant="subtitle2" color="text.primary">
          {element?.displayValue || element.value || 'No value'}
        </Link>
      </Typography>
    </Stack>
  </Stack>
);

export default function SaveDialog({ open, handleClose, onSave, changes = [] }: any) {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Are you sure you want to save?</DialogTitle>
      <DialogContent sx={{ width: '35rem' }}>
        <DialogContentText
          sx={{ paddingTop: 1, fontSize: '0.875rem' }}
          id="alert-dialog-description"
        >
          The following fields will be set:
        </DialogContentText>
        <DialogContent sx={{ paddingLeft: 0, paddingTop: 1 }}>
          {changes.map((element: any, index: number) => changeElement(element, element.field))}
        </DialogContent>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="inherit">
          Go back
        </Button>
        <Button onClick={onSave} autoFocus>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
