import { Card, Stack, TextField, Typography } from '@material-ui/core';
import { DesktopDatePicker } from '@material-ui/lab';
import { useState } from 'react';
import { SubscriptionAutoComplete } from './inputs/SubscriptionAutoComplete';

export const GeneralInfoContainer = ({ disabled, contract, onChange }: any) => {
  const [fields, setFields] = useState<any>(contract);
  const [sub, setSub] = useState<any>({});

  const handleChange = (
    value: any,
    field: string,
    displayName: string = '',
    displayValue: string = ''
  ) => {
    setFields({
      ...fields,
      [field]: value
    });
    onChange(value, field, displayName, displayValue);
  };

  const handleSubUpdate = (sub: any) => {
    handleChange(sub?.id, 'subscription_id', 'Subscription', sub.name);
    setSub(sub);
  };

  return (
    <Card sx={{ py: 3, px: 3 }}>
      <Stack spacing={3}>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
          <TextField
            fullWidth
            disabled={disabled}
            id="userId"
            label="User ID"
            value={fields?.user_id || ''}
            onChange={(event: any) => handleChange(event.target.value, 'user_id')}
          />
        </Stack>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
          <SubscriptionAutoComplete
            disabled={disabled}
            label="Subscription"
            id={fields?.subscription_id || ''}
            onChange={(value: any) => handleSubUpdate(value)}
          />
        </Stack>
        <Stack direction={{ xs: 'column', sm: 'column' }} spacing={{ xs: 3, sm: 2 }}>
          <DesktopDatePicker
            label="Valid until"
            inputFormat="MM/dd/yyyy"
            value={fields?.valid_until || null}
            onChange={(value: any) =>
              handleChange(value, 'valid_until', 'Valid until', value.toString())
            }
            renderInput={(params: any) => <TextField {...params} />}
          />
        </Stack>
        {((!sub?.id && contract?.subscription?.contract_period_months) ||
          sub?.contract_period_months) && (
          <Stack direction={{ xs: 'column', sm: 'column' }} spacing={{ xs: 3, sm: 2 }}>
            <DesktopDatePicker
              label="Binding until"
              inputFormat="MM/dd/yyyy"
              value={fields?.binding_until || null}
              onChange={(value: any) =>
                handleChange(value, 'binding_until', 'Binding until', value.toString())
              }
              renderInput={(params: any) => <TextField {...params} />}
            />
          </Stack>
        )}
        {contract?.subscription?.kwh_limit == null ? (
          ''
        ) : (
          <Stack direction={{ xs: 'column', sm: 'column' }} spacing={{ xs: 3, sm: 2 }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant="body2">Subscription kWh limit:</Typography>
              <Typography variant="body2">{contract.subscription.kwh_limit}</Typography>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant="body2">Contract kWh charged:</Typography>
              <Typography variant="body2">{contract.kwh_charged}</Typography>
            </div>
          </Stack>
        )}
      </Stack>
    </Card>
  );
};
