import { Card, Stack, TextField } from '@material-ui/core';
import Divider from '@mui/material/Divider';
import { useEffect, useState } from 'react';
import { ExternalCountryAutoComplete } from '../ExternalCountryAutoComplete';

export const GeneralInfoContainer = ({ disabled, country, onChange, onChangeMany }: any) => {
  const [fields, setFields] = useState<any>({});

  useEffect(() => {
    setFields(country);
  }, [country]);

  const handleChange = (
    value: any,
    field: string,
    displayName: string = '',
    displayValue: string = ''
  ) => {
    setFields({
      ...fields,
      [field]: value
    });
    onChange(value, field, displayName, displayValue);
  };

  const handleImport = (country: any) => {
    if (country?.name?.common) {
      const newFields: any = {
        name: country?.name?.common,
        code_alpha_3: country?.cca3,
        code_alpha_2: country?.cca2,
        time_zone: country.timezones?.length > 0 && country.timezones[0],
        flag: country.flag
      };

      setFields({
        ...fields,
        ...newFields
      });
      onChangeMany(newFields);
    }
  };

  return (
    <Card sx={{ py: 3, px: 3 }}>
      <Stack spacing={3}>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
          <TextField
            fullWidth
            disabled={disabled}
            id="something-like-1"
            label="Name"
            autoComplete="off"
            value={fields?.name || ''}
            onChange={(event: any) => handleChange(event.target.value, 'name')}
          />
        </Stack>

        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
          <TextField
            fullWidth
            disabled={disabled}
            id="something-like-8"
            label="Flag"
            value={fields?.flag || ''}
            onChange={(event: any) => handleChange(event.target.value, 'flag')}
          />
          <TextField
            fullWidth
            disabled={disabled}
            id="something-like-2"
            label="Code (Alpha 2)"
            value={fields?.code_alpha_2 || ''}
            onChange={(event: any) => handleChange(event.target.value, 'code_alpha_2')}
          />
          <TextField
            fullWidth
            disabled={disabled}
            id="something-like-2"
            label="Code (Alpha 3)"
            value={fields?.code_alpha_3 || ''}
            onChange={(event: any) => handleChange(event.target.value, 'code_alpha_3')}
          />
        </Stack>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
          <TextField
            type="number"
            fullWidth
            disabled={disabled}
            id="something-like-3"
            label="Value Added Tax (VAT)"
            value={fields?.vat || ''}
            onChange={(event: any) => handleChange(parseInt(event?.target?.value || '', 10), 'vat')}
          />
        </Stack>

        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
          <TextField
            fullWidth
            disabled={disabled}
            id="something-like-6"
            label="Elton VAT number"
            value={fields?.elton_vat_number || ''}
            onChange={(event: any) => handleChange(event.target.value, 'elton_vat_number')}
          />
        </Stack>

        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
          <TextField
            fullWidth
            disabled={disabled}
            id="something-like-7"
            label="Time zone"
            value={fields?.time_zone || ''}
            onChange={(event: any) => handleChange(event.target.value, 'time_zone')}
          />
        </Stack>
      </Stack>
    </Card>
  );
};
