import { BasicGridTransformedFilters } from '../../@types/grid';
import axios from '../../utils/axios';
import { CreateCurrencyType, UpdateCurrencyType } from '../../@types/charging/currency';
import {CONFIG} from "../../config";

const BASE_CURRENCY_URL = `${CONFIG.SERVICES_ADMIN_URL}/api/v1/admin/currencies`;

/**
 * Get all currencies
 * @returns
 * @param page
 * @param pageSize
 * @param order
 * @param filters
 */
export const getCurrencies = async (
  page: number = 1,
  pageSize: number = 25,
  order: string | null = null,
  filters: BasicGridTransformedFilters | null = {}
) => {
  const params: any = {};
  if (filters) {
    for (const filter of Object.keys(filters)) {
      if (filters[filter]) {
        params[filter] = filters[filter];
      }
    }
  }

  if (page) {
    params.page = page;
  }

  if (pageSize) {
    params.page_size = pageSize;
  }

  if (order) {
    params.order_by = order;
  }

  const response = await axios.get(`${BASE_CURRENCY_URL}`, { params });

  // Map date to js dates
  response.data.data = response.data.data.map((offer: any) => ({
    ...offer,

    created_at: offer.created_at ? new Date(offer.created_at) : null,
    updated_at: offer.updated_at ? new Date(offer.updated_at) : null
  }));
  return response;
};

/**
 * Get a single currency by id
 * @param id
 * @returns
 */
export const getCurrency = async (id: string) => axios.get(`${BASE_CURRENCY_URL}/${id}`);

/**
 * Create a new currency
 * @param currency
 * @returns
 */
export const createCurrency = async (currency: CreateCurrencyType) =>
  axios.post(`${BASE_CURRENCY_URL}`, currency);

export const updateCurrency = async (id: string, currency: UpdateCurrencyType) =>
  axios.put(`${BASE_CURRENCY_URL}/${id}`, currency);

export const deleteCurrency = async (id: string) => axios.delete(`${BASE_CURRENCY_URL}/${id}`);
