import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import React from 'react';

export const gridColumns: GridColDef[] = [
  { field: 'id', headerName: 'Id', width: 140 },
  {
    field: 'dataProvider',
    headerName: 'Data Provider',
    width: 200,
    renderCell: (params: GridRenderCellParams) => (
      <p>{params.value && (params.value as any)?.name}</p>
    )
  },
  { field: 'newlocations', headerName: 'New', width: 200 },
  { field: 'updatedLocations', headerName: 'Updated', width: 200 },
  { field: 'notAllowedLocations', headerName: 'Not allowed', width: 200 },
  {
    field: 'timeInMs',
    headerName: 'Run time',
    width: 200,
    renderCell: (params: GridRenderCellParams) => <p>{params.value && `${params.value} ms`}</p>
  },
  {
    field: 'createdAt',
    headerName: 'Created At',
    width: 200,
    renderCell: (params: any) => params.value.toLocaleString()
  },
  {
    field: 'updatedAt',
    headerName: 'Last Update',
    width: 200,
    renderCell: (params: any) => params.value.toLocaleString()
  }
];
