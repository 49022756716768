
import { Icon } from '@iconify/react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  FormGroup,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import { styled } from '@material-ui/system';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FormControlLabel } from '@mui/material';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import React, { useCallback, useState } from 'react';

const StyledHeader = styled(AccordionSummary)({
  width: '100%',
  padding: '1rem',
  background: '#F4F6F8',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderRadius: '16px',
  '& p': {
    fontWeight: 520,
    fontSize: '1.02rem',
    letterSpacing: '1px',
    display: 'flex',
    alignItems: 'center'
  },
  '& svg': { marginRight: '.4rem' },
  '& #header-content': {
    width: '100%'
  }
});

const StyledLeftHeader = styled('div')({
  float: 'left',
  display: 'flex',
  alignItems: 'center'
});

const StyledRightHeader = styled('div')({
  float: 'right',
  display: 'flex',
  justifyContent: 'flex-end'
});

const FlexCenter = styled('div')({
  display: 'flex',
  alignItems: 'center'
});

const FlexCenterSpaceBetween = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between'
});

const FlexSpaceBetween = styled('div')({
  display: 'flex',
  justifyContent: 'space-between'
});

const RestrictionFieldContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  margin: '1rem'
});

const InputFieldContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column'
});

const InputFieldLabel = styled(Typography)({
  marginBottom: '0.5rem',
  display: 'flex',
  alignItems: 'center',
  '& svg': { marginRight: '.4rem' }
});

const Devider = styled(Typography)({
  marginLeft: '1rem',
  marginRight: '1rem'
});

const StyledTextField = styled(TextField)({ marginBottom: '1rem', background: 'white' });

const RestrictionOverview = ({ from, to, unit, icon }: any) => {
  let text = '';

  if (unit === 's') {
    if (to > 60 || from > 60) {
      to = to && (to / 60).toFixed(2);
      from = from && (from / 60).toFixed(2);
      unit = 'm';
    }
    if (to > 60 || from > 60) {
      to = to && (to / 60).toFixed(2);
      from = from && (from / 60).toFixed(2);
      unit = 'h';
    }
  }
  if (from && to) {
    text = `${from} - ${to}${unit}`;
  } else if (from) {
    text = `${from}${unit}+`;
  } else if (to) {
    text = `0 - ${to}${unit}`;
  }

  return text ? (
    <FlexCenter>
      <Icon icon={icon} />
      <Typography>{text}</Typography>
    </FlexCenter>
  ) : (
    <></>
  );
};

export const TariffCardHeader = ({
  tariff,
  operator,
  disabled,
  changed,
  setChangedDetails
}: any) => {
  const [restrictions, setRestrictions] = useState<any>({
    external_price_id: tariff?.external_price_id || '',
    start_datetime: tariff?.start_datetime || '',
    end_datetime: tariff?.end_datetime || '',
    from_power: tariff?.from_power === 0 || tariff?.from_power ? parseInt(tariff.from_power) : '',
    to_power: tariff?.to_power || '',
    region: tariff?.region?.elspot_region || '',
    comment: tariff?.comment || ''
  });

  const handleChange = async (event: any, field: string, shouldParseInt: boolean = false) => {
    const value = shouldParseInt
      ? parseInt(event.target.value as string, 10) || 0
      : event.target.value;

    setRestrictions({ ...restrictions, [field]: value });
    let fieldToUpdate = value === '' ? null : value;

    // Region, should be kept if set or updated if changes are made
    if (field == 'region' && fieldToUpdate != null) {
      fieldToUpdate = {
        region: fieldToUpdate,
        country: operator.country.code_alpha_3
      };
      field = 'optional_elspot_search';
    }
    if (field != 'region' && restrictions.region.length > 0) {
      setChangedDetails({
        ...changed,
        price_search: {
          ...restrictions,
          [field]: fieldToUpdate,
          price_details: null,
          operator: null,
          optional_elspot_search: {
            region: restrictions.region,
            country: operator.country.code_alpha_3
          }
        }
      });
    } else {
      setChangedDetails({
        ...changed,
        price_search: {
          ...restrictions,
          [field]: fieldToUpdate,
          price_details: null,
          operator: null
        }
      });
    }
  };


  const updateTariffRestriction = useCallback(
    AwesomeDebouncePromise(async (partialTariff: any) => {
      //await updateTariff(tariff.operator_id, tariff.primary_id, partialTariff);
    }, 600),
    []
  );

  // @ts-ignore
  // @ts-ignore
  return (
    <div>
      <Accordion>
        <StyledHeader
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div id="header-content">
            <StyledLeftHeader>
              <FlexCenter>
                <Typography>
                  {'External price id: ' +
                    (restrictions.external_price_id != ''
                      ? restrictions.external_price_id
                      : 'BASE PRICE (NOT SET)')}
                </Typography>
              </FlexCenter>
              {restrictions.region != '' && (
                <FlexCenter>
                  <Typography>{',    Region: ' + restrictions.region}</Typography>
                </FlexCenter>
              )}
              <FlexCenter>
                <Typography style={{ color: 'lightgray' }}>{',   ' + tariff.primary_id}</Typography>
              </FlexCenter>
            </StyledLeftHeader>

            <StyledRightHeader>
              <RestrictionOverview
                from={restrictions.from_power}
                to={restrictions.to_power}
                unit="kW"
                icon="bi:lightning-charge"
              />
            </StyledRightHeader>
          </div>
        </StyledHeader>
        <AccordionDetails style={{ display: 'flex', justifyContent: 'space-between' }}>
          <FlexCenter style={{ alignItems: 'flex-start' }}>
            <RestrictionFieldContainer>
              <InputFieldLabel>
                <Tooltip title="External price id is usually the imported tariff id">
                  <Button color="inherit">External price id</Button>
                </Tooltip>
              </InputFieldLabel>
              <InputFieldContainer>
                <StyledTextField
                  disabled={disabled}
                  id="external_price_id"
                  label="External price id"
                  variant="outlined"
                  type="text"
                  value={restrictions.external_price_id}
                  onChange={(e) => handleChange(e, 'external_price_id', false)}
                />
              </InputFieldContainer>
            </RestrictionFieldContainer>
            <RestrictionFieldContainer>
              <InputFieldLabel>
                <Tooltip
                  title={`Power restrictions on when tariff is applicable. Set in kW and will only apply to connectors meeting the desired range. 
                  To represent AC charging set 0-22 where the range is including. For DC this will be for example 23-30000.`}
                >
                  <Button color="inherit">
                    <Icon icon="healthicons:i-schedule-school-date-time" />
                    From and to power restriction
                  </Button>
                </Tooltip>
              </InputFieldLabel>
              <InputFieldContainer>
                <StyledTextField
                  disabled={disabled}
                  id="from-power"
                  label="From, inclusive"
                  required={true}
                  variant="outlined"
                  value={
                    restrictions?.from_power === 0 || restrictions?.from_power
                      ? parseInt(restrictions.from_power)
                      : ''
                  }
                  onChange={(e) => handleChange(e, 'from_power', true)}
                />
                <StyledTextField
                  disabled={disabled}
                  id="to-power"
                  label="To, inclusive"
                  required={true}
                  variant="outlined"
                  type="number"
                  value={restrictions.to_power}
                  onChange={(e) => handleChange(e, 'to_power', true)}
                />
              </InputFieldContainer>
            </RestrictionFieldContainer>
            <RestrictionFieldContainer>
              <InputFieldLabel>
                <Tooltip
                  title={`Comment that is added manually by admin to show human readable text regarding the price. 
                    Will disappear on imported tariffs on each import. Will stay the same on base prices.`}
                >
                  <Button color="inherit">
                    <Icon icon="healthicons:i-schedule-school-date-time" />
                    From and to power restriction
                  </Button>
                </Tooltip>
              </InputFieldLabel>
              <InputFieldContainer>
                <StyledTextField
                  disabled={disabled}
                  style={{ width: '20rem' }}
                  id="comment"
                  label="Price comment"
                  required={true}
                  variant="outlined"
                  multiline
                  value={restrictions.comment}
                  onChange={(e) => handleChange(e, 'comment', false)}
                />
              </InputFieldContainer>
            </RestrictionFieldContainer>
          </FlexCenter>

          <FlexCenterSpaceBetween style={{ alignItems: 'flex-start' }}>
            <RestrictionFieldContainer>
              <InputFieldLabel>
                <Tooltip
                  title={`Start date with time and end date with time defines what interval the tariff is active. 
                  These can be used to schedule future tariff changes. Given as text for simplicity, do not change format:
                  YYYY-MM-DDTHH:MM:SSZ in UTC time.`}
                >
                  <Button color="inherit">
                    <Icon icon="healthicons:i-schedule-school-date-time" />
                    Start and end datetime for when tariff is active
                  </Button>
                </Tooltip>
              </InputFieldLabel>
              <InputFieldContainer>
                <StyledTextField
                  disabled={disabled}
                  id="from-time-of-day"
                  label="From (UTC YYYY-MM-DDTHH:MM:SSZ)"
                  required={true}
                  variant="outlined"
                  value={restrictions.start_datetime}
                  onChange={(e) => handleChange(e, 'start_datetime', false)}
                />
                <StyledTextField
                  disabled={disabled}
                  id="to-time-of-day"
                  label="To (UTC YYYY-MM-DDTHH:MM:SSZ)"
                  required={true}
                  variant="outlined"
                  value={restrictions.end_datetime}
                  onChange={(e) => handleChange(e, 'end_datetime', false)}
                />
              </InputFieldContainer>
            </RestrictionFieldContainer>
            <RestrictionFieldContainer style={{ display: 'block' }}>
              <InputFieldLabel>
                <Tooltip title="Which elspot region is active">
                  <Button color="inherit">
                    <Icon icon="bi:calendar-day" />
                    Elspot region
                  </Button>
                </Tooltip>
              </InputFieldLabel>
              <InputFieldContainer>
                <StyledTextField
                  disabled={disabled}
                  id="elspot_region"
                  label="Region (e.g. NO2)"
                  variant="outlined"
                  value={restrictions.region}
                  onChange={(e) => handleChange(e, 'region', false)}
                />
              </InputFieldContainer>
            </RestrictionFieldContainer>
          </FlexCenterSpaceBetween>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
