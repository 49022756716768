// routes
// components
import SvgIconStyle from '../../components/SvgIconStyle';
import {PATH_DASHBOARD} from '../../routes/paths';
// ----------------------------------------------------------------------

const getIcon = (name: string) => (
    <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{width: '100%', height: '100%'}}/>
);

const ICONS = {
    user: getIcon('ic_user'),
    ecommerce: getIcon('ic_ecommerce'),
    sessions: getIcon('ic_unpaid'),
    analytics: getIcon('ic_analytics'),
    dashboard: getIcon('ic_dashboard'),
    explore: getIcon('ic_explore'),
    station: getIcon('ic_station'),
    dataLog: getIcon('ic_data_log'),
    subscription: getIcon('ic_product_subscriptions'),
    operator: getIcon('ic_operator'),
    country: getIcon('ic_country'),
    charging: getIcon('ic_notification_shipping'),
    status: getIcon('align_center'),
    emoji: getIcon('emoji'),
    voucher: getIcon('ic_booking'),
    partners: <p>🤝</p>,
    loyalty: <p>🥇</p>,
};

const getSideNavBar = (user: any) => [
    // GENERAL
    // ----------------------------------------------------------------------
    {
        subheader: 'general',
        items: [
            {
                title: 'Analytics',
                path: PATH_DASHBOARD.general.analytics,
                icon: ICONS.dashboard,
                children: [
                    {title: 'Overview', path: PATH_DASHBOARD.general.analytics},
                    {title: 'Revenue', path: PATH_DASHBOARD.general.revenues},
                    {title: 'Conversion', path: PATH_DASHBOARD.general.conversion},
                    {title: 'Charging', path: PATH_DASHBOARD.general.charging}
                ]
            },
            ...(user.role !== 'Dashboard'
                ? [
                    {
                        title: 'Subscriptions',
                        path: PATH_DASHBOARD.subscriptions.offers,
                        icon: ICONS.subscription,
                        children: [
                            {title: 'Manage offers', path: PATH_DASHBOARD.subscriptions.offers},
                            {title: 'Manage Contracts', path: PATH_DASHBOARD.subscriptions.contracts},
                            {title: 'View Transactions', path: PATH_DASHBOARD.subscriptions.transactions}
                        ]
                    },
                    {
                        title: 'B2B',
                        path: PATH_DASHBOARD.b2b.companies,
                        icon: ICONS.ecommerce,
                        children: [
                            {title: 'Companies', path: PATH_DASHBOARD.b2b.companies},
                            {title: 'Invoices', path: PATH_DASHBOARD.b2b.invoice},
                            {title: 'Report all', path: PATH_DASHBOARD.b2b.weekly},
                            {title: 'Plans', path: PATH_DASHBOARD.b2b.plans}
                        ]
                    },
                    {
                        title: 'Voucher',
                        path: PATH_DASHBOARD.voucher.companies,
                        icon: ICONS.voucher,
                        children: [{title: 'Companies', path: PATH_DASHBOARD.voucher.companies}]
                    },
                    {
                        title: 'Partners',
                        path: PATH_DASHBOARD.partners.partners,
                        icon: ICONS.partners
                    },
                    {
                        title: 'User',
                        path: PATH_DASHBOARD.user.get,
                        icon: ICONS.emoji,
                        children: [{title: 'User details', path: PATH_DASHBOARD.user.get}]
                    },
                    {
                        title: 'Loyalty',
                        path: PATH_DASHBOARD.loyalty.tiers,
                        icon: ICONS.loyalty,
                        children: [
                            {title: 'Tiers', path: PATH_DASHBOARD.loyalty.tiers},
                            {title: 'User admin', path: PATH_DASHBOARD.loyalty.user}
                        ]
                    }
                ]
                : [])
        ]
    },
    ...(user.role !== 'Dashboard'
        ? [
            {
                subheader: 'Financial Management',
                items: [
                    {title: 'Reports', path: PATH_DASHBOARD.financials.reports, icon: ICONS.ecommerce}
                ]
            },
            {
                subheader: 'Charging Management',
                items: [
                    {
                        title: 'Charging Status',
                        path: `${PATH_DASHBOARD.charging.status}`,
                        icon: ICONS.charging,
                        children: [
                            {
                                title: 'OICP Status',
                                path: `${PATH_DASHBOARD.charging.status}/authorization`
                            },
                            {title: 'OCPI Status', path: `${PATH_DASHBOARD.charging.status}/commands`}
                        ]
                    },
                    {title: 'Sessions', path: PATH_DASHBOARD.charging.sessions, icon: ICONS.sessions}
                ]
            },
            {
                subheader: 'Operator Management',
                items: [
                    {
                        title: 'Operators',
                        path: PATH_DASHBOARD.operators,
                        icon: ICONS.operator
                    },
                    {
                        title: 'Countries',
                        path: PATH_DASHBOARD.countries,
                        icon: ICONS.country
                    },
                    {
                        title: 'Currencies',
                        path: PATH_DASHBOARD.currencies,
                        icon: ICONS.country
                    }
                ]
            }
        ]
        : []),
    {
        subheader: 'Location Management',
        items: [
            ...(user.role !== 'Dashboard'
                ? [
                    {
                        title: 'Locations',
                        path: PATH_DASHBOARD.locations.manage.find,
                        icon: ICONS.station,
                        children: [
                            {title: 'Find', path: PATH_DASHBOARD.locations.manage.find},
                            {title: 'View', path: PATH_DASHBOARD.locations.manage.edit}
                        ]
                    }
                ]
                : []),
            {
                title: 'Map',
                path: PATH_DASHBOARD.locations.map,
                icon: ICONS.explore
            },
            ...(user.canEdit
                ? [
                    {
                        title: 'Logs',
                        path: PATH_DASHBOARD.locations.logs.importJobs,
                        icon: ICONS.dataLog,
                        children: [{title: 'Import jobs', path: PATH_DASHBOARD.locations.logs.importJobs}]
                    }
                ]
                : [])
        ]
    }
];

export default getSideNavBar;
