import { BasicGridFilter, BasicGridTransformedFilters } from '../../../@types/grid';

export const getFilters = (filters: BasicGridTransformedFilters): BasicGridFilter[] => [
  {
    type: 'date_from',
    displayName: 'Date from',
    value: filters.date_from || '',
    icon: 'ant-design:user-outlined',
    disabled: true,
    specialId: (Date.now() + Math.random()).toString()
  },
  {
    type: 'date_to',
    displayName: 'Date to',
    value: filters.date_to || '',
    icon: 'ant-design:user-outlined',
    disabled: true,
    specialId: (Date.now() + Math.random()).toString()
  }
];
