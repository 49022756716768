import { Card, Typography } from '@material-ui/core';
import { useCallback, useState } from 'react';
import { SwitchWithLabel } from './inputs/SwitchWithLabel';

export const BooleanContainer = ({ disabled, contract, onChange }: any) => {
  const [fields, setFields] = useState<any>(contract);

  const handleChange = useCallback(
    (value: any, field: string, displayName: string, displayValue: string) => {
      setFields({
        ...fields,
        [field]: value
      });
      onChange(value, field, displayName, displayValue);
    },
    [fields, onChange, setFields]
  );

  return (
    <Card sx={{ py: 12.8, px: 3 }}>
      <SwitchWithLabel
        disabled={disabled}
        value={fields?.renew_contract}
        title="Auto-renew"
        type="renew_contract"
        onChange={handleChange}
        description="Should the contract be auto-renewed on expiration?"
      />

      <div style={{ width: '40rem' }} />
      <Typography style={{ marginTop: '1rem' }} variant="body2" sx={{ mb: 0.5 }}>
        * Marked values are not set.
      </Typography>
    </Card>
  );
};
