import axios from '../../../utils/axios';
import {BasicGridTransformedFilters} from '../../../@types/grid';
import {CONFIG} from "../../../config";

export const generateDinteroReport = async (dateFrom: Date, dateTo: Date) => {
    try {
        const result = await axios({
                url: `${CONFIG.SERVICES_ADMIN_URL}/api/v1/admin/payment/generate-report?date_from=${dateFrom.toISOString()}&date_to=${dateTo.toISOString()}`,
                method: 'POST',
                responseType: 'blob',
            }
        );
        if (result) {
            return result.data;
        }
        return null;
    } catch (error: any) {
        console.error(error);
        return {
            companies: null,
            error: error.response?.data,
            code: error.response?.code
        };
    }
};

export const fetchDinteroReports = async () => {
    try {
        const result = await axios({
                url: `${CONFIG.SERVICES_ADMIN_URL}/api/v1/admin/payment/get-report`,
                method: 'GET',
            }
        );
        if (result) {
            return result.data;
        }
        return null;
    } catch (error: any) {
        console.error(error);
        return {
            companies: null,
            error: error.response?.data,
            code: error.response?.code
        };
    }
};

export const getAnalyticsIncome = async (currency: string, fromDate: Date, toDate: Date) => {
    try {
        const result = await axios.get(
            `${CONFIG.SERVICES_ADMIN_URL}/api/v1/admin/payment/analytics/income/${currency}?date_from=${fromDate.toISOString()}&date_to=${toDate.toISOString()}`
        );
        if (result) {
            return result.data;
        }
        return null;
    } catch (error: any) {
        console.error(error);
        return {
            companies: null,
            error: error.response?.data,
            code: error.response?.code
        };
    }
};

export const getAnalyticsIncomeB2B = async (currency: string, fromDate: Date, toDate: Date) => {
    try {
        const result = await axios.get(
            `${CONFIG.SERVICES_ADMIN_URL}/api/v1/admin/payment/analytics/b2b/${currency}?date_from=${fromDate.toISOString()}&date_to=${toDate.toISOString()}`
        );
        if (result) {
            return result.data;
        }
        return null;
    } catch (error: any) {
        console.error(error);
        return {
            companies: null,
            error: error.response?.data,
            code: error.response?.code
        };
    }
};

export const getAnalyticsIncomeDiscounted = async (
    currency: string,
    fromDate: Date,
    toDate: Date
) => {
    try {
        const result = await axios.get(
            `${CONFIG.SERVICES_ADMIN_URL}/api/v1/admin/payment/analytics/discounted/${currency}?date_from=${fromDate.toISOString()}&date_to=${toDate.toISOString()}`
        );
        if (result) {
            return result.data;
        }
        return null;
    } catch (error: any) {
        console.error(error);
        return {
            companies: null,
            error: error.response?.data,
            code: error.response?.code
        };
    }
};

export const getAnalyticsSubscription = async (currency: string, fromDate: Date, toDate: Date) => {
    try {
        const result = await axios.get(
            `${CONFIG.SERVICES_ADMIN_URL}/api/v1/admin/payment/analytics/subscription/${currency}?date_from=${fromDate.toISOString()}&date_to=${toDate.toISOString()}`
        );
        if (result) {
            return result.data;
        }
        return null;
    } catch (error: any) {
        console.error(error);
        return {
            companies: null,
            error: error.response?.data,
            code: error.response?.code
        };
    }
};

export const getWeekly = async (
    date_from: Date,
    date_to: Date,
    id?: string,
    company_name?: string,
    page: number | null = 1,
    pageSize: number | null = 25,
    order: string | null = null,
    filters: BasicGridTransformedFilters | null = {}
) => {
    const params: any = {};
    if (filters) {
        for (const filter of Object.keys(filters)) {
            if (filters[filter]) {
                params[filter] = filters[filter];
            }
        }
    }

    if (date_from && date_to) {
        date_from.setHours(4);
        date_to.setHours(4);
        const fromDate = date_from.toISOString();
        const toDate = date_to.toISOString();
        params.date_to = toDate.substring(0, toDate.indexOf('T'));
        params.date_from = fromDate.substring(0, fromDate.indexOf('T'));
    }

    if (company_name) {
        params.company_name = company_name;
    }

    if (page) {
        params.page = page;
    }

    if (pageSize) {
        params.page_size = pageSize;
    }

    if (order) {
        params.order_by = order;
    }
    let response;
    if (id !== undefined) {
        response = await axios.get(`${CONFIG.SERVICES_ADMIN_URL}/api/v1/admin/payment/b2b/weekly/${id}`, {params});
    } else {
        response = await axios.get(`${CONFIG.SERVICES_ADMIN_URL}/api/v1/admin/payment/b2b/weekly`, {params});
    }

    response.data.data = response.data.data.map((weekly: any) => ({
        ...weekly
    }));
    return response;
};

/**
 * Fetch a single session
 * @param id
 * @returns
 */
export const getSession = async (id: string) => {
    const response = await axios.get(`${CONFIG.SERVICES_ADMIN_URL}/api/v1/admin/payment/sessions/${id}`);
    return response;
};

export const getSessions = async (
    page: number = 1,
    pageSize: number = 25,
    order: string | null = null,
    filters: BasicGridTransformedFilters | null = {}
) => {
    const params: any = {};
    if (filters) {
        for (const filter of Object.keys(filters)) {
            if (filters[filter]) {
                params[filter] = filters[filter];
            }
        }
    }

    if (page) {
        params.page = page;
    }

    if (pageSize) {
        params.page_size = pageSize;
    }

    if (order) {
        params.order_by = order;
    }

    const response = await axios.get(`${CONFIG.SERVICES_ADMIN_URL}/api/v1/admin/payment/sessions`, {
        params
    });
    response.data.data = response.data.data.map((sessions: any) => ({
        ...sessions,
        created_at: new Date(sessions.created_at)
    }));
    return response;
};

export const getInvoice = async (
    currency: string,
    date_from: Date,
    date_to: Date,
    page: number = 1,
    pageSize: number = 25,
    order: string | null = null,
    filters: BasicGridTransformedFilters | null = {}
) => {
    const params: any = {};
    if (filters) {
        for (const filter of Object.keys(filters)) {
            if (filters[filter]) {
                params[filter] = filters[filter];
            }
        }
    }

    if (date_from && date_to) {
        date_from.setHours(4);
        date_to.setHours(4);
        const fromDate = date_from.toISOString();
        const toDate = date_to.toISOString();

        params.date_to = toDate.substring(0, toDate.indexOf('T'));
        params.date_from = fromDate.substring(0, fromDate.indexOf('T'));
    }

    if (page) {
        params.page = page;
    }

    if (pageSize) {
        params.page_size = pageSize;
    }

    if (order) {
        params.order_by = order;
    }

    const response = await axios.get(`${CONFIG.SERVICES_ADMIN_URL}/api/v1/admin/payment/invoice/${currency}`, {
        params
    });
    response.data.data = response.data.data.map((weekly: any) => ({
        ...weekly
    }));
    return response;
};
