import axios from '../../../utils/axios';
import { BasicGridTransformedFilters } from '../../../@types/grid';
import {CONFIG} from "../../../config";

/**
 * Get all contracts
 * @param page
 * @param pageSize
 * @param order
 * @param filters
 * @returns
 */
export const getContracts = async (
  page: number = 1,
  pageSize: number = 25,
  order: string | null = null,
  filters: BasicGridTransformedFilters | null = {}
) => {
  const params: any = {};
  if (filters) {
    for (const filter of Object.keys(filters)) {
      if (filters[filter]) {
        params[filter] = filters[filter];
      }
    }
  }

  if (page) {
    params.page = page;
  }

  if (pageSize) {
    params.page_size = pageSize;
  }

  if (order) {
    params.order_by = order;
  }

  const response = await axios.get(`${CONFIG.SERVICES_ADMIN_URL}/admin/v1/subscriptions/contracts`, { params });
  // Map date to js dates
  response.data.data = response.data.data.map((offer: any) => ({
    ...offer,
    created_at: offer.created_at ? new Date(offer.created_at) : null,
    updated_at: offer.updated_at ? new Date(offer.updated_at) : null,
    valid_until: offer.valid_until ? new Date(offer.valid_until) : null,
    binding_until: offer.binding_until ? new Date(offer.binding_until) : null
  }));
  return response;
};

/**
 * Get a single contract
 * @param id
 * @returns
 */
export const getContract = async (id: string) =>
  axios.get(`${CONFIG.SERVICES_ADMIN_URL}/admin/v1/subscriptions/contracts/${id}`);

/**
 * Create a new contract
 * @param contract
 * @returns
 */
export const createContract = async (contract: any) =>
  axios.post(`${CONFIG.SERVICES_ADMIN_URL}/admin/v1/subscriptions/contracts`, contract);

/**
 * Update existing contract
 * @param id
 * @param contract
 * @returns
 */
export const updateContract = async (id: string, contract: any) =>
  axios.put(`${CONFIG.SERVICES_ADMIN_URL}/admin/v1/subscriptions/contracts/${id}`, contract);

/**
 * Delete contract
 * @param id
 * @param contract
 * @returns
 */
export const destoryContract = async (id: string) =>
  axios.delete(`${CONFIG.SERVICES_ADMIN_URL}/admin/v1/subscriptions/contract/${id}`);
