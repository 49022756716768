import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import BasicGrid from 'components/grid/basic-grid';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ContractsProps } from '../../../@types/subscriptions/contract';
import {
  createOperatorv2,
  deleteOperatorv2,
  getOperatorsv2
} from '../../../api/elton-payments/operators';
import { UserContext } from '../../../contexts/UserContext';
import DeleteDialog from './deleteDialog';
import { gridColumns } from './gridColumns';
import { getFilters } from './gridFilters';

const CreateButton = () => {
  const navigate = useNavigate();

  const handleClick = async () => {
    try {
      navigate(`/dashboard/operators/edit`);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Button onClick={handleClick} style={{ width: '3.5rem', height: '3.5rem' }} variant="outlined">
      <AddIcon />
    </Button>
  );
};

export const Grid = (props: ContractsProps) => {
  // Delete modal
  const [deleteEntry, setDeleteEntry] = useState<any>({ open: false, id: null });

  // Val to handle grid refresh
  const [forceUpdate, setForceUpdate] = useState(0);

  const [{ user }]: any = React.useContext(UserContext);

  const handleDelete = async () => {
    try {
      await deleteOperatorv2(deleteEntry?.id);
      setForceUpdate(forceUpdate + 1);
      setDeleteEntry({ open: false, id: null });
    } catch (e) {
      console.error(e);
    }
  };

  // Add feilds that is only used by the table
  const mapOperators = async (...args: any) => {
    const result = await getOperatorsv2(...args);
    result.data.data = result.data.data.map((o: any) => ({
      id: o.primary_id,
      ...o,
      actions: {
        id: o.primary_id,
        deleteCallback: (id: string) => setDeleteEntry({ open: true, id }),
        canEdit: user?.canEdit
      }
    }));
    return result;
  };

  return (
    <>
      <BasicGrid
        fetchFunction={mapOperators}
        columns={gridColumns}
        pages={props.pages}
        filters={getFilters(props.filters)}
        order={props.order}
        onUpdate={props.onUpdate}
        RightNavbarItem={user.canEdit && CreateButton}
        forceUpdate={forceUpdate}
      />
      <DeleteDialog
        handleClose={() => setDeleteEntry({ open: false, id: null })}
        open={deleteEntry.open}
        handleDelete={handleDelete}
      />
    </>
  );
};
