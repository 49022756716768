import { alpha } from '@material-ui/core/styles';

// ----------------------------------------------------------------------

function createGradient(color1: string, color2: string) {
  return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

interface GradientsPaletteOptions {
  primary: string;
  info: string;
  success: string;
  warning: string;
  error: string;
  elton: string;
}

interface ChartPaletteOptions {
  violet: string[];
  blue: string[];
  green: string[];
  yellow: string[];
  red: string[];
}

declare module '@material-ui/core/styles/createPalette' {
  interface TypeBackground {
    neutral: string;
  }

  interface SimplePaletteColorOptions {
    lighter: string;
    darker: string;
  }

  interface PaletteColor {
    lighter: string;
    darker: string;
  }

  interface Palette {
    gradients: GradientsPaletteOptions;
    chart: ChartPaletteOptions;
  }

  interface PaletteOptions {
    gradients: GradientsPaletteOptions;
    chart: ChartPaletteOptions;
  }
}

declare module '@material-ui/core' {
  interface Color {
    0: string;
    500_8: string;
    500_12: string;
    500_16: string;
    500_24: string;
    500_32: string;
    500_48: string;
    500_56: string;
    500_80: string;
  }
}

// SETUP COLORS
export const PRIMARY = {
  lighter: '#C8FACD',
  light: '#5BE584',
  main: '#4CD7AF',
  dark: '#007B55',
  darker: '#005249'
};

export const PINK = {
  lighter: '#f8bbd0',
  light: '#f06292',
  main: '#e91e63',
  dark: '#c2185b',
  darker: '#880e4f'
};

export const ORANGE = {
  lighter: '#ffe0b2',
  light: '#ffb74d',
  main: '#fd8308',
  dark: '#f57c00',
  darker: '#e65100'
};

export const ELTON_DARK = {
  lighter: '#8CA1AE',
  light: '#5F7C8E',
  main: '#1A445D',
  dark: '#102D3F'
};

export const ELTON_GREEN = {
  lighter: '#9DD3CB',
  light: '#63b9ac',
  main: '#3CAA99',
  dark: '#2d9182',
  darker: '#298679',
  custom: '#40BF9B'
};
export const ELTON_ORANGE = {
  light: '#fead7F',
  main: '#ff9960',
  dark: '#f58342'
};
export const ELTON_BLUE = {
  main: '#aecef1',
  dark: '#58b2ce'
};

const SECONDARY = {
  lighter: '#9DD3CB',
  light: '#63b9ac',
  main: '#3CAA99',
  dark: '#2d9182',
  darker: '#298679',
  custom: '#40BF9B'
};
export const INFO = {
  lighter: '#D0F2FF',
  light: '#74CAFF',
  main: '#1890FF',
  dark: '#0C53B7',
  darker: '#04297A'
};
const SUCCESS = {
  lighter: '#4CD7AF',
  light: '#4CD7AF',
  main: '#4CD7AF',
  dark: '#3ba898',
  darker: '#3ba898'
};
export const WARNING = {
  lighter: '#FFF7CD',
  light: '#FFE16A',
  main: '#FF9960',
  dark: '#B78103',
  darker: '#7A4F01'
};
export const ERROR = {
  lighter: '#FFE7D9',
  light: '#FFA48D',
  main: '#FF4842',
  dark: '#B72136',
  darker: '#7A0C2E'
};

export const ERROR_DARK = {
  lighter: '#b2ebf2',
  light: '#4dd0e1',
  main: '#00bcd4',
  dark: '#0097a7',
  darker: '#006064'
};

export const INFO_DARK = {
  lighter: '#c8e6c9',
  light: '#81c784',
  main: '#4caf50',
  dark: '#388e3c',
  darker: '#1b5e20'
};

const GREY = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
  500_8: alpha('#919EAB', 0.08),
  500_12: alpha('#919EAB', 0.12),
  500_16: alpha('#919EAB', 0.16),
  500_24: alpha('#919EAB', 0.24),
  500_32: alpha('#919EAB', 0.32),
  500_48: alpha('#919EAB', 0.48),
  500_56: alpha('#919EAB', 0.56),
  500_80: alpha('#919EAB', 0.8)
};

const GRADIENTS = {
  primary: createGradient(PRIMARY.light, PRIMARY.main),
  info: createGradient(INFO.light, INFO.main),
  success: createGradient(SUCCESS.light, SUCCESS.main),
  warning: createGradient(WARNING.light, WARNING.main),
  error: createGradient(ERROR.light, ERROR.main),
  elton: createGradient(ELTON_GREEN.light, ELTON_GREEN.main)
};

const CHART_COLORS = {
  violet: ['#826AF9', '#9E86FF', '#D0AEFF', '#F7D2FF'],
  blue: ['#2D99FF', '#83CFFF', '#A5F3FF', '#CCFAFF'],
  green: ['#2CD9C5', '#60F1C8', '#A4F7CC', '#C0F2DC'],
  yellow: ['#FFE700', '#FFEF5A', '#FFF7AE', '#FFF3D6'],
  red: ['#FF6C40', '#FF8F6D', '#FFBD98', '#FFF2D4']
};

const COMMON = {
  common: { black: '#000', white: '#fff' },
  primary: { ...PRIMARY, contrastText: '#fff' },
  secondary: { ...SECONDARY, contrastText: '#fff' },
  info: { ...INFO, contrastText: '#fff' },
  success: { ...SUCCESS, contrastText: GREY[800] },
  warning: { ...WARNING, contrastText: GREY[800] },
  error: { ...ERROR, contrastText: '#fff' },
  elton: { ...ELTON_GREEN, contrastText: '#f5f5f5' },
  grey: GREY,
  gradients: GRADIENTS,
  chart: CHART_COLORS,
  divider: GREY[500_24],
  action: {
    hover: GREY[500_8],
    selected: GREY[500_16],
    disabled: GREY[500_80],
    disabledBackground: GREY[500_24],
    focus: GREY[500_24],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48
  }
};

const DARK = {
  common: { black: '#000', white: '#fff' },
  primary: { ...ORANGE, contrastText: '#fff' },
  secondary: { ...ORANGE, contrastText: '#fff' },
  info: { ...INFO_DARK, contrastText: '#fff' },
  success: { ...ORANGE, contrastText: GREY[800] },
  warning: { ...ERROR_DARK, contrastText: GREY[800] },
  error: { ...ERROR, contrastText: '#fff' },
  elton: { ...ELTON_GREEN, contrastText: '#fff' },
  grey: GREY,
  gradients: GRADIENTS,
  chart: CHART_COLORS,
  divider: GREY[500_24],
  action: {
    hover: GREY[500_8],
    selected: GREY[500_16],
    disabled: GREY[500_80],
    disabledBackground: GREY[500_24],
    focus: GREY[500_24],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48
  }
};

const palette = {
  light: {
    ...COMMON,
    text: { primary: GREY[800], secondary: GREY[600], disabled: GREY[500] },
    background: { paper: '#fff', default: '#fff', neutral: GREY[200] },
    action: { active: GREY[600], ...COMMON.action }
  },
  dark: {
    ...DARK,
    text: { primary: ORANGE.main, secondary: ORANGE.light, disabled: ORANGE.lighter },
    background: { paper: GREY[800], default: GREY[900], neutral: GREY[500_16] },
    action: { active: GREY[500], ...COMMON.action }
  }
};

export default palette;
