import searchFill from '@iconify/icons-eva/search-fill';
import { Icon } from '@iconify/react';
import { Box, InputAdornment, OutlinedInput, Toolbar } from '@material-ui/core';
// material
import { styled } from '@material-ui/core/styles';
import React from 'react';
import { UserContext } from '../../../contexts/UserContext';

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  [theme.breakpoints.down('md')]: {
    flexWrap: 'wrap'
  }
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: '100%',
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  '&.Mui-focused': { width: '110%', boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`
  }
}));

// ----------------------------------------------------------------------

export default function SpecialToolbar({
  filters,
  type,
  form
}: {
  filters: any[];
  type: string | undefined;
  form?: any;
}) {
  const [{ user }]: any = React.useContext(UserContext);
  return (
    <RootStyle>
      {filters.map((filter: any) => (
        <SearchStyle
          key={filter.type}
          style={{ margin: '1rem' }}
          value={filter.value}
          onChange={(e) => {
            if (filter.onUpdate) {
              filter.onUpdate(e.target.value);
            }
          }}
          placeholder={filter.displayName}
          startAdornment={
            <InputAdornment position="start">
              <Box
                component={Icon}
                icon={filter.icon || searchFill}
                sx={{ color: 'text.disabled' }}
              />
            </InputAdornment>
          }
        />
      ))}
      {user?.canEdit && form}
    </RootStyle>
  );
}
