import { Icon } from '@iconify/react';
import { Checkbox, Tooltip } from '@material-ui/core';
import { styled } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';

const StyledIcon = styled(Icon)(({ theme }) => ({
  color: 'black',
  height: '1.5rem',
  width: '1.5rem',
  '&:hover': {
    color: theme.palette.primary.main
  }
}));

const CenterLink = styled(Link)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginRight: '0.7rem'
}));

const Action = (contract: any) => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      width: '100%',
      height: '100%'
    }}
  >
    {contract?.id ? (
      <>
        <CenterLink to={`/dashboard/subscriptions/contracts/${contract.id}`}>
          <Tooltip title="View details">
            <StyledIcon icon="bx:show" />
          </Tooltip>
        </CenterLink>
        <CenterLink to={`/dashboard/subscriptions/transactions?contract_id=${contract.id}`}>
          <Tooltip title="Show all transaction for this contract">
            <StyledIcon icon="bx:transfer" />
          </Tooltip>
        </CenterLink>
      </>
    ) : (
      ''
    )}
  </div>
);

export const gridColumns: GridColDef[] = [
  {
    field: 'actions',
    headerName: ' ',
    description: 'Actions',
    width: 80,
    sortable: false,
    renderCell: (params: any) => Action(params.value)
  },
  {
    field: 'valid_until',
    headerName: 'Valid until',
    width: 200,
    renderCell: (params: any) => (params.value ? params.value.toLocaleString() : '')
  },
  {
    field: 'binding_until',
    headerName: 'Binding until',
    description: 'The contract must be updated every month until this date. (optional)',
    width: 200,
    renderCell: (params: any) => (params.value ? params.value.toLocaleString() : '')
  },
  {
    field: 'renew_contract',
    headerName: 'Auto renew',
    width: 170,
    renderCell: (params: GridRenderCellParams) => <Checkbox disabled checked={!!params?.value} />
  },
  {
    field: 'subCountry',
    headerName: 'Country',
    width: 150,
    renderCell: (params: any) => (params.value ? params.value : '')
  },
  {
    field: 'subName',
    headerName: 'Offer name',
    width: 200,
    renderCell: (params: any) => (params.value ? params.value : '')
  },
  {
    field: 'kwh_charged',
    headerName: 'kWh charged',
    width: 120
  },
  {
    field: 'subKwhLimit',
    headerName: 'kWh limit',
    width: 120
  },
  {
    field: 'created_at',
    headerName: 'Created At',
    width: 170,
    renderCell: (params: any) => (params.value ? params.value.toLocaleString() : '')
  },
  {
    field: 'updated_at',
    headerName: 'Updated At',
    width: 170,
    renderCell: (params: any) => (params.value ? params.value.toLocaleString() : '')
  },
  { field: 'id', headerName: 'ID', width: 350 },
  { field: 'user_id', headerName: 'User ID', width: 350 },
  { field: 'firebase_id', headerName: 'Firebase User ID', width: 350 },
  {
    field: 'subscription_id',
    headerName: 'Subscription Id',
    width: 350,
    renderCell: (params: GridRenderCellParams) => (
      <Link
        to={`/dashboard/subscriptions/offers/${params.value}/edit`}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        {params.value}
      </Link>
    )
  }
];
