import { GridColDef } from '@mui/x-data-grid';
import { Plan } from '../../../@types/b2b/b2b';
import FormDialogs from './FormDialog';

const Action = (plan: Plan) => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      width: '100%',
      height: '100%'
    }}
  >
    {<FormDialogs isEdit={true} inputPlan={plan} />}
  </div>
);

export const gridColumns: GridColDef[] = [
  {
    field: 'actions',
    headerName: ' ',
    description: 'Actions',
    width: 90,
    sortable: false,
    renderCell: (params) => Action(params.row)
  },
  {
    field: 'primary_id',
    headerName: 'ID',
    width: 310,
    renderCell: (params) => params.value
  },
  {
    field: 'name',
    headerName: 'Name',
    width: 200,
    renderCell: (params) => params.value
  },
  {
    field: 'created_at',
    headerName: 'Created at',
    width: 200,
    renderCell: (params) => params.value.toLocaleString()
  },
  {
    field: 'updated_at',
    headerName: 'Updated at',
    width: 200,
    renderCell: (params) => params.value.toLocaleString()
  }
];
