import { BasicGridFilter, BasicGridTransformedFilters } from '../../../@types/grid';

export const getFilters = (filters: BasicGridTransformedFilters): BasicGridFilter[] => [
  {
    type: 'name',
    displayName: 'Name',
    value: filters?.name || '',
    icon: 'ant-design:user-outlined'
  },
  {
    type: 'id',
    displayName: 'Id',
    value: filters?.id || '',
    icon: 'ic:outline-business'
  },
  {
    type: 'country',
    displayName: 'Country',
    value: filters?.id || '',
    icon: 'fe:globe'
  }
];
