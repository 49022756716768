import axios from '../../../utils/axios';
import { BasicGridTransformedFilters } from '../../../@types/grid';
import {CONFIG} from "../../../config";

/**
 * Get all transactions
 * @param page
 * @param pageSize
 * @param order
 * @param filters
 * @returns
 */
export const getTransactions = async (
  page: number = 1,
  pageSize: number = 25,
  order: string | null = null,
  filters: BasicGridTransformedFilters | null = {}
) => {
  const params: any = {};
  if (filters) {
    for (const filter of Object.keys(filters)) {
      if (filters[filter]) {
        params[filter] = filters[filter];
      }
    }
  }

  if (page) {
    params.page = page;
  }

  if (pageSize) {
    params.page_size = pageSize;
  }

  if (order) {
    params.order_by = order;
  }

  const response = await axios.get(`${CONFIG.SERVICES_ADMIN_URL}/admin/v1/subscriptions/transactions`, { params });
  // Map date to js dates
  response.data.data = response.data.data.map((trans: any) => ({
    ...trans,
    created_at: new Date(trans.created_at),
    updated_at: new Date(trans.updated_at),
    valid_until: trans.valid_until && new Date(trans.valid_until)
  }));
  return response;
};
