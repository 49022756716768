import { GridColDef } from '@mui/x-data-grid';

export const gridColumns: GridColDef[] = [
  {
    field: 'company_name',
    headerName: 'Company name',
    width: 200,
    sortable: false,
    renderCell: (params: any) => (params.value ? params.value.toLocaleString() : '')
  },
  {
    field: 'transaction_id',
    headerName: 'Transaction id',
    width: 350,
    renderCell: (params: any) => (params.value ? params.value.toLocaleString() : '')
  },
  {
    field: 'reference',
    headerName: 'Reference',
    width: 200,
    sortable: false,
    renderCell: (params: any) => (params.value ? params.value.toLocaleString() : '')
  },
  {
    field: 'start_time',
    headerName: 'Start time',
    width: 250,
    sortable: false,
    renderCell: (params: any) => (params.value ? params.value.toLocaleString() : '')
  },
  {
    field: 'stop_time',
    headerName: 'Stop time',
    width: 250,
    sortable: false,
    renderCell: (params: any) => (params.value ? params.value.toLocaleString() : '')
  },
  {
    field: 'operator',
    headerName: 'Operator',
    width: 170,
    sortable: false,
    renderCell: (params: any) => (params.value ? params.value.toLocaleString() : '')
  },

  {
    field: 'user',
    headerName: 'User',
    width: 200,
    sortable: false,
    renderCell: (params: any) => (params.value ? params.value.toLocaleString() : '')
  },

  {
    field: 'energy',
    headerName: 'Energy',
    width: 170,
    sortable: false,
    renderCell: (params: any) => (params.value ? params.value.toLocaleString() : '')
  },
  {
    field: 'total_cost',
    headerName: 'Total cost',
    width: 170,
    sortable: false,
    renderCell: (params: any) => (params.value ? params.value.toLocaleString() : '')
  },
  {
    field: 'currency',
    headerName: 'Currency',
    width: 170,
    sortable: false,
    renderCell: (params: any) => (params.value ? params.value.toLocaleString() : '')
  },
  {
    field: 'created_at',
    headerName: 'Created at',
    width: 200,
    renderCell: (params: any) => (params.value ? params.value.toLocaleString() : '')
  },
  // values below this comment is for the export. they don't have to be visible
  {
    field: 'customer_name',
    headerName: 'Customer name',
    width: 200,
    renderCell: (params: any) => (params.value ? params.value.toLocaleString() : '')
  },
  {
    field: 'description',
    headerName: 'Description',
    width: 200,
    renderCell: (params: any) => (params.value ? params.value.toLocaleString() : '')
  },
  {
    field: 'currency_export',
    headerName: 'Currency',
    width: 200,
    renderCell: (params: any) => (params.value ? params.value.toLocaleString() : '')
  },
  {
    field: 'country',
    headerName: 'Country',
    width: 200,
    renderCell: (params: any) => (params.value ? params.value.toLocaleString() : '')
  },
  {
    field: 'sales_price',
    headerName: 'Sales price',
    width: 200,
    renderCell: (params: any) => (params.value ? params.value.toLocaleString() : '')
  }
];
