import { getImportLogs } from 'api/elton-stations/admin/importJobs';
import BasicGrid from 'components/grid/basic-grid';
import { gridColumns } from './gridColumns';
import { getFilters } from './gridFilters';

export const List = (props: any) => {
  // Add feilds that is only used by the table
  const mapLogs = async (...args: any) => {
    const result = await getImportLogs(...args);
    return result;
  };

  return (
    <BasicGrid
      fetchFunction={mapLogs}
      columns={gridColumns}
      pages={props.pages}
      filters={getFilters(props.filters)}
      order={props.order}
      onUpdate={props.onUpdate}
    />
  );
};
