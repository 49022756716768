import { Container } from '@material-ui/core';
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import Page from 'components/Page';
import { useLocation, useNavigate } from 'react-router-dom';
import { PATH_DASHBOARD } from 'routes/paths';
import { GridState } from '../../@types/grid';
import { Grid } from '../../components/operators/list/Grid';

export default function ListOpeartors() {
  // Get query strings
  const path: any = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(path.search);

  /**
   * Updates query strings
   */
  const updateQueryStrings = (data: GridState) => {
    let queryString = '?';

    // Add filters
    for (const key of Object.keys(data.filters)) {
      if (data.filters[key]) {
        queryString += `${key}=${data.filters[key]}&`;
      }
    }

    if (data.page) {
      queryString += `page=${data.page}&`;
    }
    if (data.pageSize) {
      queryString += `pageSize=${data.pageSize}&`;
    }
    if (data.order) {
      queryString += `orderBy=${data.order}&`;
    }

    navigate({ search: queryString.substring(0, queryString.length - 1) });
  };

  return (
    <Page title="Operators">
      <Container maxWidth="xl">
        <HeaderBreadcrumbs
          heading="View operators"
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            { name: 'Operators', href: PATH_DASHBOARD.operators }
          ]}
        />
        <Grid
          filters={{
            user_id: query.get('user_id') || '',
            subscription_id: query.get('subscription_id') || ''
          }}
          pages={{
            page: parseInt(query.get('page') || '1', 10),
            pageSize: parseInt(query.get('pageSize') || '25', 10)
          }}
          order={query.get('order') || '-created_at'}
          onUpdate={updateQueryStrings}
        />
      </Container>
    </Page>
  );
}
