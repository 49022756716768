import {BasicGridTransformedFilters} from '../../@types/grid';
import axios from '../../utils/axios';
import {CONFIG} from "../../config";

const BASE_COUNTRIES_URL = `${CONFIG.SERVICES_ADMIN_URL}/api/v1/admin/countries`;

/**
 * Get all countries
 * @returns
 * @param name
 */
export const getCountries = async (name: string | null = null) =>
  axios.get(`${BASE_COUNTRIES_URL}`, { params: { name } }).then((res) => res.data);

export const getCountry = async (countryCode: string | null = null) =>
  axios.get(`${BASE_COUNTRIES_URL}/alpha/${countryCode}`);

export const getCountriesv2 = async (
  page: number = 1,
  pageSize: number = 25,
  order: string | null = null,
  filters: BasicGridTransformedFilters | null = {}
) => {
  const params: any = {};
  if (filters) {
    for (const filter of Object.keys(filters)) {
      if (filters[filter]) {
        params[filter] = filters[filter];
      }
    }
  }

  if (page) {
    params.page = page;
  }

  if (pageSize) {
    params.page_size = pageSize;
  }

  if (order) {
    params.order_by = order;
  }

  const response = await axios.get(`${BASE_COUNTRIES_URL}`, { params });

  // Map date to js dates
  response.data.data = response.data.data.map((offer: any) => ({
    id: offer.primary_id,
    ...offer,
    created_at: offer.created_at ? new Date(offer.created_at) : null,
    updated_at: offer.updated_at ? new Date(offer.updated_at) : null
  }));
  return response;
};

/**
 * Get a single country by id
 * @param id
 * @returns
 */
export const getCountryv2 = async (id: string | null = null) =>
  axios.get(`${BASE_COUNTRIES_URL}/${id}`);

/**
 * Create a new country
 * @param country
 * @returns
 */
export const createCountryv2 = async (country: any) => axios.post(`${BASE_COUNTRIES_URL}`, country);

export const updateCountryv2 = async (id: string, country: any) =>
  axios.put(`${BASE_COUNTRIES_URL}/${id}`, country);

export const deleteCountryv2 = async (id: string) => axios.delete(`${BASE_COUNTRIES_URL}/${id}`);
