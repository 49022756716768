import { Container } from '@material-ui/core';
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import Page from 'components/Page';
import { useLocation, useNavigate } from 'react-router-dom';
import { PATH_DASHBOARD } from 'routes/paths';
import { GridState } from '../../../@types/grid';
import { LocationListFilters } from '../../../@types/locations';
import { List } from '../../../components/locations/logs/import-jobs/List';

export default function ImportJobs() {
  // Get query strings
  const path: any = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(path.search);

  /**
   * Updates query strings
   */
  const updateQueryStrings = (data: GridState) => {
    let queryString = '?';

    // Add filters
    for (const key of Object.keys(data.filters) as Array<keyof LocationListFilters>) {
      if (data.filters[key]) {
        queryString += `${key}=${data.filters[key]}&`;
      }
    }

    if (data.page) {
      queryString += `page=${data.page}&`;
    }
    if (data.pageSize) {
      queryString += `pageSize=${data.pageSize}&`;
    }
    if (data.order) {
      queryString += `orderBy=${data.order}&`;
    }

    navigate({ search: queryString.substring(0, queryString.length - 1) });
  };

  return (
    <Page title="LocationList">
      <Container maxWidth="xl">
        <HeaderBreadcrumbs
          heading="Import jobs"
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            { name: 'Location Management', href: PATH_DASHBOARD.locations.logs.importJobs },
            { name: 'Import Jobs', href: PATH_DASHBOARD.locations.logs.importJobs }
          ]}
        />

        <List
          filters={{
            dataProvider: query.get('dataProvider') || ''
          }}
          pages={{
            page: parseInt(query.get('page') || '1', 10),
            pageSize: parseInt(query.get('pageSize') || '25', 10)
          }}
          order={query.get('order') || '-createdAt'}
          onUpdate={updateQueryStrings}
        />
      </Container>
    </Page>
  );
}
