
import { Alert, AlertTitle, Card } from '@material-ui/core';
import { DataGrid, GridRowsProp, GridSortModel, GridToolbar } from '@mui/x-data-grid';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import axios from 'axios';
import { useCallback, useEffect, useRef, useState } from 'react';
import { BasicGridFilter, BasicGridProps } from '../../../@types/grid';
import { getOrder, transformFilters, transformOrder } from '../../../utils/grid';
import SpecialToolbar from '../../b2b/utils/SpecialToolbar';
import Toolbar from './extensions/Toolbar';

export const BasicGrid = (props: BasicGridProps) => {
  // Sort and filters
  const [filters, setFilters] = useState<BasicGridFilter[]>(props?.filters || []);
  const [sortModel, setSortModel] = useState<GridSortModel>(getOrder(props?.order));

  // Pages
  const [page, setPage] = useState<number>((props?.pages?.page || 1) - 1);
  const [pageSize, setPageSize] = useState<number>(props?.pages?.pageSize || 25);

  // Grid variables
  const [rows, setRows] = useState<GridRowsProp>([]);
  const [totalRowCount, setTotalRowCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>('');

  // Debounce function for loading locations on filter change
  const fetchDebounce = useCallback(AwesomeDebouncePromise(props.fetchFunction, 500), []);

  // Workaround to only fetch init state once
  const firstUpdate = useRef(true);
  const [didMount, setDidMount] = useState(false);

  /**
   * Update sort state, if value has changed
   * @param newModel
   */
  const handleSortModelChange = (newModel: GridSortModel) => {
    if (
      (newModel?.length === 0 && sortModel?.length !== 0) ||
      (newModel?.length !== 0 && sortModel?.length === 0)
    ) {
      setSortModel(newModel);
    } else if (newModel[0].field !== sortModel[0].field || newModel[0].sort !== sortModel[0].sort) {
      setSortModel(newModel);
    }
  };

  /**
   * Update filter on text change
   * @param type
   * @param newValue
   */
  const handleFilterUpdate = (type: string, newValue: string) => {
    setFilters(
      filters.map((filter: BasicGridFilter) => {
        if (filter.type === type) {
          filter.value = newValue;
        }
        return filter;
      })
    );
  };

  /**
   * Handle data that will be passed to parent
   */
  const handleUpdate = useCallback(() => {
    if (props.onUpdate) {
      props.onUpdate({
        filters: transformFilters(filters),
        order: transformOrder(sortModel),
        page,
        pageSize
      });
    }
  }, [filters, page, pageSize, sortModel]);

  /**
   * Hack to figure out if we do client-side or server-side stuff for sorting or getting new rows, only really set to client-side for b2b-export
   */
  const isClientSide = (): boolean => {
    return props.exportFields != null;
  };

  /**
   * Update grid rows
   */
  const updateRows = async (updateNow: boolean = false) => {
    setLoading(true);
    setError('');
    try {
      // Format request and get data
      const result: any = await (updateNow
        ? props.fetchFunction(
            page + 1,
            pageSize,
            transformOrder(sortModel),
            transformFilters(filters)
          )
        : fetchDebounce(page + 1, pageSize, transformOrder(sortModel), transformFilters(filters)));

      handleUpdate();
      if (result?.data?.data) {
        setRows(result.data.data);
        if (result.data?.meta) {
          setTotalRowCount(result.data.meta.total);
        } else if (result.data.total) {
          setTotalRowCount(result.data.total);
        } else {
          setTotalRowCount(result.data.data.length);
        }
      } else {
        setRows([]);
        setTotalRowCount(0);
      }
    } catch (e) {
      console.error(e)
      if (axios.isAxiosError(e)) {
        if (e?.response?.status) {
          setError(`${e.response?.status} ${e.response.statusText}`);
        } else {
          setError('Unable to fetch items due to an unknown network error.');
        }
      } else {
        setError('Unknown error.');
      }
      setRows([]);
      setTotalRowCount(0);
    }
    setLoading(false);
  };

  /**
   * Update table on page or sortModel changes
   */
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    if (!isClientSide()) {
      updateRows(true);
    }
  }, [page, pageSize, sortModel, props.forceUpdate]);

  /**
   * Update table on filters changes
   */
  // useEffect(() => {
  //   updateRows(true);
  // }, []);

  useEffect(() => {
    updateRows(false);
  }, [filters]);

  useEffect(() => {
    setDidMount(true);
    return () => setDidMount(false);
  }, []);

  if (!didMount) {
    return null;
  }

  const getCustomRowClassName = (row: any): string => {
    if (props.rowClassNameFunc != null) {
      return props.rowClassNameFunc(row);
    }

    const validUntil = new Date(row.valid_until);
    if (validUntil.getTime() < Date.now()) {
      return 'deleted';
    }
    return '';
  };

  return (
    <Card>
      {props.add ? (
        <SpecialToolbar
          type={props.type}
          form={props.form}
          filters={filters.map((filter: BasicGridFilter) => ({
            ...filter,
            onUpdate: (value: string) => {
              handleFilterUpdate(filter.type, value);
            }
          }))}
        />
      ) : (
        <Toolbar
          RightNavbarItem={props?.RightNavbarItem}
          filters={filters.map((filter: BasicGridFilter) => ({
            ...filter,
            onUpdate: (value: string) => {
              handleFilterUpdate(filter.type, value);
            }
          }))}
        />
      )}
      <DataGrid
        style={{
          height: '65vh',
          minHeight: '30rem',
          width: '100%',
          paddingRight: '1.5rem',
          paddingLeft: '1.5rem'
        }}
        sx={{
          '.deleted': {
            backgroundColor: 'rgba(200,25,25,0.3)'
          }
        }}
        disableColumnFilter
        rows={rows}
        getRowClassName={(params) => getCustomRowClassName(params.row)}
        page={page}
        columns={props.columns}
        pagination
        pageSize={pageSize}
        onPageSizeChange={(pageSize) => setPageSize(pageSize)}
        rowCount={totalRowCount}
        onPageChange={(newPage) => setPage(newPage)}
        loading={loading}
        sortModel={sortModel}
        onSortModelChange={handleSortModelChange}
        componentsProps={{ toolbar: { csvOptions: { fields: props.exportFields } } }}
        sortingMode={isClientSide() ? 'client' : 'server'}
        paginationMode={isClientSide() ? 'client' : 'server'}
        components={{
          Toolbar: GridToolbar,
          NoRowsOverlay: (props?: any) => (
            <div
              style={{
                display: 'flex',
                marginTop: '5rem',
                justifyContent: 'center'
              }}
            >
              {error ? (
                <Alert style={{ width: '50%' }} severity="error">
                  <AlertTitle>Unable to fetch items. 😢</AlertTitle>
                  <b>{error}</b> <br />
                  (Be aware, some resources may require Appgate/VPN)
                </Alert>
              ) : (
                <span>
                  <b>
                    {props?.noItemsFoundText || 'Unable to find any items for the given query.'}
                  </b>
                </span>
              )}
            </div>
          )
        }}
      />
    </Card>
  );
};
