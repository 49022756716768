import menu2Fill from '@iconify/icons-eva/menu-2-fill';
import { Icon } from '@iconify/react';
import { AppBar, Box, IconButton, Stack, Toolbar } from '@material-ui/core';
// material
import { alpha, styled } from '@material-ui/core/styles';
import DnsRoundedIcon from '@mui/icons-material/DnsRounded';
// hooks
import { Chip } from '@mui/material';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { useCallback, useRef, useState } from 'react';
//
import { MHidden } from '../../components/@material-extend';
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
import AccountPopover from './AccountPopover';
import { Plane } from './Plane';
// ----------------------------------------------------------------------

const env = process.env.REACT_APP_DEPLOY_ENV || 'Localhost';
const DRAWER_WIDTH = 280;
const COLLAPSE_WIDTH = 102;

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH}px)`
  }
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5)
  }
}));

// ----------------------------------------------------------------------

type DashboardNavbarProps = {
  onOpenSidebar: VoidFunction;
};

export default function DashboardNavbar({ onOpenSidebar }: DashboardNavbarProps) {
  const ref: any = useRef(0);
  const { isCollapse } = useCollapseDrawer();

  const [showEasterEgg, setShowEasterEgg] = useState(false);

  const handleClickDebounce = () => {
    if (ref.current === 5) {
      setShowEasterEgg(true);
      setTimeout(() => setShowEasterEgg(false), 10000);
    }
    ref.current = 0;
  };
  // Debounce function for loading locations on filter change
  const fetchDebounce = useCallback(() => AwesomeDebouncePromise(handleClickDebounce, 1000), []);

  return (
    <RootStyle
      sx={{
        ...(isCollapse && {
          width: { lg: `calc(100% - ${COLLAPSE_WIDTH}px)` }
        })
      }}
    >
      <ToolbarStyle>
        <MHidden width="lgUp">
          <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
            <Icon icon={menu2Fill} />
          </IconButton>
        </MHidden>

        <Box sx={{ flexGrow: 1 }}> </Box>

        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Chip
            onClick={() => {
              ref.current += 1;
              fetchDebounce();
            }}
            sx={{ height: '2.5rem', paddingRight: '0.4rem', paddingLeft: '0.8rem' }}
            avatar={<DnsRoundedIcon />}
            label={env}
          />
        </Box>

        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
          <AccountPopover />
        </Stack>
      </ToolbarStyle>
      <Plane show={showEasterEgg} />
    </RootStyle>
  );
}
