import { Icon } from '@iconify/react';
import ClearIcon from '@mui/icons-material/Clear';
import { styled, Tooltip } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';

const StyledIcon = styled(Icon)(({ theme }) => ({
  color: 'black',
  height: '1.5rem',
  width: '1.5rem',
  '&:hover': {
    color: theme.palette.primary.main
  }
}));

const DeleteIcon = styled(ClearIcon)(({ theme }) => ({
  color: 'black',
  height: '1.5rem',
  width: '1.5rem',
  cursor: 'pointer',
  '&:hover': {
    color: theme.palette.error.main
  }
}));

const Action = ({ id, deleteCallback, canEdit }: any) =>
  id ? (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        height: '100%'
      }}
    >
      <Link
        to={`/dashboard/currencies/edit/${id}`}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Tooltip title="View details">
          <StyledIcon icon="bx:show" />
        </Tooltip>
      </Link>
      {canEdit && (
        <DeleteIcon
          style={{ cursor: 'pointer' }}
          color="action"
          onClick={() => deleteCallback(id)}
        />
      )}
    </div>
  ) : (
    <div />
  );

export const gridColumns: GridColDef[] = [
  {
    field: 'actions',
    headerName: ' ',
    description: 'Actions',
    width: 80,
    sortable: false,
    renderCell: (params: any) => Action(params.value)
  },
  {
    field: 'currency',
    headerName: 'Currency',
    width: 100
  },
  {
    field: 'currency_symbol',
    headerName: 'Name',
    width: 100
  },
  {
    field: 'country_code',
    headerName: 'Alpha 3',
    width: 150
  },
  {
    field: 'deposit_reservation',
    headerName: 'Deposit reservation',
    width: 150
  },
  {
    field: 'payment_reservation',
    headerName: 'Payment reservation',
    width: 150
  },
  {
    field: 'minimum_capture',
    headerName: 'Minimum capture',
    width: 150
  },
  {
    field: 'created_at',
    headerName: 'Created At',
    width: 170,
    renderCell: (params: any) => (params.value ? params.value.toLocaleString() : '')
  },
  {
    field: 'updated_at',
    headerName: 'Updated At',
    width: 200,
    renderCell: (params: any) => (params.value ? params.value.toLocaleString() : '')
  },
  { field: 'primary_id', headerName: 'Id', width: 350 }
];
