export const userReducer = (
  state: any = initialState,
  action: { type: 'login' | 'logout' | 'loading'; payload: any }
) => {
  switch (action.type) {
    case 'login':
      return {
        ...state,
        loading: false,
        isAuthenticated: true,
        user: action.payload
      };
    case 'logout':
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
        user: { name: 'displayName', email: 'test@test.com' }
      };
    case 'loading':
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};

export const initialState = {
  loading: true,
  isAuthenticated: false,
  user: { name: 'displayName', email: 'test@test.com' },
  accessToken: ''
};
