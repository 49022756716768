import { BasicGridTransformedFilters } from '../../@types/grid';
import axios from '../../utils/axios';
import {CONFIG} from "../../config";

/**
 * Get operators v2
 * @returns
 * @param page
 * @param pageSize
 * @param order
 * @param filters
 */
export const getOperatorsv2 = async (
  page: number = 1,
  pageSize: number = 25,
  order: string | null = null,
  filters: BasicGridTransformedFilters | null = {}
) => {
  const params: any = {};
  if (filters) {
    for (const filter of Object.keys(filters)) {
      if (filters[filter]) {
        params[filter] = filters[filter];
      }
    }
  }

  if (page) {
    params.page = page;
  }

  if (pageSize) {
    params.page_size = pageSize;
  }

  if (order) {
    params.order_by = order;
  }

  const response = await axios.get(`${CONFIG.SERVICES_ADMIN_URL}/api/v1/admin/operators`, { params });

  // Map date to js dates
  response.data.data = response.data.data.map((offer: any) => ({
    ...offer,
    created_at: offer.created_at ? new Date(offer.created_at) : null,
    updated_at: offer.updated_at ? new Date(offer.updated_at) : null
  }));
  return response;
};

/**
 * Get a single operator by id
 * @param id
 * @returns
 */
export const getOperatorv2 = async (id?: string) =>
  axios.get(`${CONFIG.SERVICES_ADMIN_URL}/api/v1/admin/operators/${id ? id : ''}`);

/**
 * Create a new operator
 * @param operator
 * @returns
 */
export const createOperatorv2 = async (operator: any) =>
  axios.post(`${CONFIG.SERVICES_ADMIN_URL}/api/v1/admin/operators`, operator);

/**
 * Update a single operator
 * @param id
 * @param operator
 * @returns
 */
export const updateOperatorv2 = async (id: string, operator: any) =>
  axios.put(`${CONFIG.SERVICES_ADMIN_URL}/api/v1/admin/operators/${id}`, operator);

/**
 * Delete a single operator
 * @param id
 * @returns
 */
export const deleteOperatorv2 = async (id: string) =>
  axios.delete(`${CONFIG.SERVICES_ADMIN_URL}/api/v1/admin/operators/${id}`);

/**
 * Get all tariff restrictions for a given operator
 */
export const getTariffs = (
  operatorId: string,
  page: number = 1,
  pageSize: number = 25,
  params: any = {}
) =>
  axios.get(`${CONFIG.SERVICES_ADMIN_URL}/api/v1/admin/prices/${operatorId}?page=${page}&page_size=${pageSize}`, {
    params
  });

/**
 * Creates, updates or delete a given price or associated detail
 * @param searchId
 * @param price
 * @returns
 */
export const addPriceDetail = (searchId: string, price: any) =>
  axios.post(`${CONFIG.SERVICES_ADMIN_URL}/api/v1/admin/prices/detail/${searchId}`, price);

export const updatePriceDetail = (searchId: string, detailId: string, price: any) =>
  axios.put(`${CONFIG.SERVICES_ADMIN_URL}/api/v1/admin/prices/detail/${searchId}/${detailId}`, price);

export const deletePriceDetail = (searchId: string, detailId: string) =>
  axios.delete(`${CONFIG.SERVICES_ADMIN_URL}/api/v1/admin/prices/detail/${searchId}/${detailId}`);

export const createOrUpdatePrice = (price: any) =>
  axios.post(`${CONFIG.SERVICES_ADMIN_URL}/api/v1/admin/prices`, price);

export const deletePrice = (searchId: string) =>
  axios.delete(`${CONFIG.SERVICES_ADMIN_URL}/api/v1/admin/prices/${searchId}`);

/**
 * Import tariffs from external source (OCPI)
 * @param operatorId
 * @returns
 */
export const fetchOCPITariffs = (operatorId: string) =>
  axios.post(`${CONFIG.SERVICES_ADMIN_URL}/admin/v2/operators/${operatorId}/tariffs/import-ocpi`);
