export const DinteroStatusCodes: { [key: string]: any } = {
  INITIATED: {
    displayValue: 'Initiated',
    color: 'info'
  },
  AUTHORIZED: {
    displayValue: 'Authorized',
    color: 'info'
  },
  AUTHORIZATION_VOIDED: {
    displayValue: 'Authorization voided',
    color: 'warning'
  },
  CAPTURED: {
    displayValue: 'Captured',
    color: 'primary'
  },
  PARTIALLY_CAPTURED: {
    displayValue: 'Partially captured',
    color: 'primary',
    variant: 'outlined'
  },
  REFUNDED: {
    displayValue: 'Refunded',
    color: 'info'
  },
  PARTIALLY_REFUNDED: {
    displayValue: 'Partially refunded',
    color: 'info',
    variant: 'outlined'
  },
  DECLINED: {
    displayValue: 'Declined',
    color: 'error'
  },
  FAILED: {
    displayValue: 'Failed',
    color: 'error'
  },
  UNINITIATED: {
    displayValue: 'Uninitiated',
    color: 'info',
    variant: 'outlined'
  },
  UNKNOWN: {
    displayValue: 'Unknown',
    color: 'warning'
  }
};
