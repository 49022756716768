import { Card } from '@material-ui/core';
import React, { useState } from 'react';
import { PriceGrid } from './PriceGrid';
import { TariffCardHeader } from './TariffCardHeader';
import {
  addPriceDetail,
  createOrUpdatePrice,
  deletePrice,
  deletePriceDetail,
  getTariffs,
  updatePriceDetail
} from '../../../../../api/elton-payments/operators';

export const TariffCard = ({
  operator,
  tariff,
  setMessage,
  filters,
  disabled,
  setDeletedState
}: any) => {
  const [editable, setEditable] = useState<boolean>(false);
  const [changedDetails, setChangedDetails] = useState<any>({});

  const [tariffUsed, setTariffUsed] = useState(tariff);
  // Grid variables
  const [addedRows, setAddedRows] = useState<Set<string>>(new Set());
  const [editedRows, setEditedRows] = useState<Set<string>>(new Set());
  const [deletedRows, setDeletedRows] = useState<Set<string>>(new Set());

  const handelSetChanged = (changed: any) => {
    setChangedDetails(changed);
  };

  const handleOperations = (type: 'EDITED' | 'ADDED' | 'DELETED', id: string) => {
    if (type === 'EDITED') {
      if (!addedRows.has(id)) {
        setEditedRows((prev) => new Set(prev.add(id)));
      }
    } else if (type === 'ADDED') {
      setAddedRows((prev) => new Set(prev.add(id)));
    } else if (type === 'DELETED') {
      setDeletedRows((prev) => new Set(prev.add(id)));
    }
  };

  const setPriceDetails = async (priceSearch: any) => {
    const price_details = [];
    for (const price of tariffUsed.price_details) {
      if (deletedRows.has(price.primary_id)) {
        continue;
      } else if (editedRows.has(price.primary_id)) {
        const item = changedDetails[price.primary_id];
        if (item != null) {
          price_details.push(item);
        }
      } else {
        price_details.push(price);
      }
    }
    for (let added of addedRows.values()) {
      const item = changedDetails[added];
      if (item != null) {
        price_details.push(item);
      }
    }
    priceSearch.price_details = price_details;
    priceSearch.operator_id = operator.primary_id;
    priceSearch.region = undefined;
    priceSearch.currency = operator.currency.currency;
    priceSearch.primary_id = tariffUsed.primary_id;
    if (price_details.length === 0) {
      setMessage({
        show: true,
        message: 'Unable to save details',
        type: 'error'
      });
      return;
    }
    const response = await createOrUpdatePrice(priceSearch);
    const id = response.data.id;
    await updateStatesAfterAction(id);
  };

  const updateStatesAfterAction = async (id: string) => {
    const result = await getTariffs(operator.primary_id, 1, 1, {
      external_id: id
    });
    setTariffUsed(result.data.data[0]);
    setEditable(false);
  };

  const handleSave = async () => {
    try {
      let response;
      if (
        addedRows.size === 1 &&
        editedRows.size === 0 &&
        deletedRows.size === 0 &&
        changedDetails.price_search == undefined
      ) {
        const [first] = addedRows;
        const price = changedDetails[first];
        response = await addPriceDetail(tariffUsed.primary_id, price);
        const id = response.data.id;
        await updateStatesAfterAction(id);
      } else if (
        editedRows.size === 1 &&
        addedRows.size === 0 &&
        deletedRows.size === 0 &&
        changedDetails.price_search == undefined
      ) {
        const [first] = editedRows;
        const price = changedDetails[first];
        response = await updatePriceDetail(tariffUsed.primary_id, price.primary_id, price);
        const id = response.data.id;
        await updateStatesAfterAction(id);
      } else if (
        deletedRows.size === 1 &&
        addedRows.size === 0 &&
        editedRows.size === 0 &&
        changedDetails.price_search == undefined
      ) {
        const [first] = deletedRows;
        response = await deletePriceDetail(tariffUsed.primary_id, first);
        const id = response.data.id;
        await updateStatesAfterAction(id);
      } else if (
        changedDetails.price_search != undefined ||
        deletedRows.size > 0 ||
        addedRows.size > 0 ||
        editedRows.size > 0
      ) {
        if (changedDetails.price_search == undefined) {
          const priceSearch = { ...tariffUsed };
          await setPriceDetails(priceSearch);
        } else {
          const priceSearch = { ...changedDetails.price_search };
          await setPriceDetails(priceSearch);
        }
      } else {
        setMessage({
          show: true,
          message: 'Unable to save details',
          type: 'error'
        });
        return;
      }

      //Cleanup state
      setAddedRows(new Set());
      setEditedRows(new Set());
      setDeletedRows(new Set());
      setChangedDetails({});

      setMessage({
        show: true,
        message: 'Price updates was successfully save.',
        type: 'success'
      });
    } catch (e) {
      setMessage({
        show: true,
        message: 'Unable to save details',
        type: 'error'
      });
    }
  };

  const handleUnlock = () => {
    setEditable(!editable);
  };

  const handleDelete = (deleted: string) => {
    setEditable(!editable);
    setDeletedState(deleted);
  };

  return (
    <Card
      sx={{
        height: 460,
        py: 2,
        px: 3,
        marginTop: '2rem',
        paddingBottom: !tariffUsed.default ? '5rem' : '0',
        ...(tariffUsed.operator_id == undefined && {
          border: 2,
          borderColor: 'error.main'
        })
      }}
    >
      <TariffCardHeader
        operator={operator}
        tariff={tariffUsed}
        setMessage={setMessage}
        disabled={!editable}
        setChangedDetails={handelSetChanged}
        changed={changedDetails}
      />
      <PriceGrid
        editable={editable}
        operator={operator}
        tariff={tariffUsed}
        filters={filters}
        callbackUnlock={handleUnlock}
        onDelete={handleDelete}
        setMessage={setMessage}
        disabled={disabled}
        setChangedDetails={handelSetChanged}
        callbackSave={handleSave}
        setRowOperation={handleOperations}
        changed={changedDetails}
      />
    </Card>
  );
};
