import { Grid } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { Button, FormControl } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import {
  createOperatorv2,
  fetchOCPITariffs,
  getOperatorv2,
  updateOperatorv2
} from '../../../api/elton-payments/operators';
import { BooleanContainer } from './BooleanContainer';
import { GeneralInfoContainer } from './GeneralInfoContainer';
import SaveDialog from './SaveDialog';
import { createCurrency, updateCurrency } from '../../../api/elton-payments/currency';
import { useNavigate } from 'react-router-dom';

const StyledButton = styled(Button)(({ theme }) => ({
  marginLeft: '1rem',
  height: '3rem',
  [theme.breakpoints.down('md')]: {
    margin: '2px',
    width: '100%'
  }
}));

export function EditOperator({
  disabled,
  id,
  setLoading,
  setMessage,
  setParentOperator,
  setId
}: any) {
  const navigate = useNavigate();
  const [saveDialog, setSaveDialog] = useState<boolean>(false);
  const [operator, setOperator] = useState<any>(null);
  const [changedOperatorFields, setChangedOperatorFields] = useState<any[]>([]);

  const fetchOperator = useCallback(
    async (newId: string) => {
      if (newId) {
        setLoading(true);
        const result = await getOperatorv2(newId);
        setOperator(result?.data.data);
        setParentOperator(result?.data.data);
        setChangedOperatorFields([]);
      }
      setLoading(false);
    },
    [setOperator, setLoading, setParentOperator]
  );

  /**
   * Fetch mew offer on id change
   */
  useEffect(() => {
    fetchOperator(id);

  }, [id]);

  /**
   * Handle input change, and update changedFields
   * @param value
   * @param field
   * @param displayName
   * @param displayValue
   */
  const handleChange = useCallback(
    (value: any, field: string, displayName: string = '', displayValue: any = '') => {
      const change = { value, field, displayName, displayValue };
      setChangedOperatorFields([...changedOperatorFields.filter((l) => l.field !== field), change]);
    },
    [changedOperatorFields, setChangedOperatorFields]
  );

  /**
   * Reset state
   */
  const handleDiscard = () => {
    setOperator({ ...operator });
    setChangedOperatorFields([]);
  };

  /**
   * Save changedFields
   */
  const handleSave = async () => {
    // Close dialog
    setSaveDialog(false);

    // Change format to object
    const update: any = {};
    for (const change of changedOperatorFields) {
      if (change.field === 'evse_prefixes') {
        change.value = change.value.toUpperCase();
        change.value = change.value.replace(/\s/g, '');
        update[change.field] = change.value.split(',');
      }
      update[change.field] = change.value?.length === 0 ? 'null' : change.value;
    }

    try {
      if (id) {
        await updateOperatorv2(operator.primary_id, update);
      } else {
        const result = await createOperatorv2(update);
        if (result.data.status == 'SUCCESS' && result.data.data.primary_id) {
          setId(result.data.data.primary_id);
          navigate(`/dashboard/operators/edit/${result.data.data.primary_id}`);
        }
      }
      // Reset view
      setChangedOperatorFields([]);

      // Show Alert
      setMessage({ show: true, message: 'Operator updated!', type: 'success' });

      // Fetch new offer
      await fetchOperator(id);
    } catch (error: any) {
      console.error(error);
      let message = 'Unknown Error';
      if (error?.status_message) {
        message = error.status_message;
      } else if (error?.message) {
        message = error?.message;
      }
      setMessage({ show: true, message, type: 'error' });
    }
  };

  const fetchTariffsUsingOCPI = async () => {
    setMessage({
      show: true,
      message: 'Fetching, this might take a long time!',
      type: 'success'
    });
    setLoading(true);
    try {
      await fetchOCPITariffs(id);
      // Reload page
      window.location.reload();
    } catch (e) {
      setMessage({ show: true, message: 'Unable to fetch tariffs!', type: 'error' });
    }
    setLoading(false);
  };

  return (
    <div>
      <FormControl style={{ width: '100%', marginBottom: '2rem' }}>
        <Grid container spacing={3}>
          {/* Search field to find offer */}
          <Grid item xs={12} md={4}>
            <StyledButton
              style={{ marginLeft: '0' }}
              variant="outlined"
              color="inherit"
              disabled={!operator?.protocol || operator.protocol !== 'OCPI' || disabled}
              onClick={fetchTariffsUsingOCPI}
            >
              Fetch tariffs using OCPI
            </StyledButton>
          </Grid>

          {/* Fake row to force new line */}
          <Grid style={{ display: 'flex', justifyContent: 'flex-end' }} item xs={12} md={8}>
            <StyledButton
              disabled={!(changedOperatorFields.length > 0)}
              variant="outlined"
              color="inherit"
              onClick={handleDiscard}
            >
              Discard changes
            </StyledButton>
            <StyledButton
              disabled={!(changedOperatorFields.length > 0)}
              variant="outlined"
              color="primary"
              onClick={() => {
                setSaveDialog(true);
              }}
            >
              Save changes
            </StyledButton>
          </Grid>

          {/* Container with image and boolean fields */}
          <Grid item xs={12} md={4}>
            <BooleanContainer disabled={disabled} operator={operator} onChange={handleChange} />
          </Grid>

          {/* Container with general information */}
          <Grid item xs={12} md={8}>
            <GeneralInfoContainer disabled={disabled} operator={operator} onChange={handleChange} />
          </Grid>
        </Grid>
      </FormControl>

      {/* Save confirmation dialog */}
      <SaveDialog
        open={saveDialog}
        handleClose={() => setSaveDialog(false)}
        onSave={handleSave}
        changes={changedOperatorFields}
      />
    </div>
  );
}
