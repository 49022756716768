import axios from '../../../utils/axios';
import {BasicGridTransformedFilters} from '../../../@types/grid';
import {CONFIG} from "../../../config";

/**
 * Get all import logs
 * @param page
 * @param pageSize
 * @param order
 * @param filters
 * @returns
 */
export const getImportLogs = async (
  page: number = 1,
  pageSize: number = 25,
  order: string | null = null,
  filters: BasicGridTransformedFilters | null = {}
) => {
  const params: any = {};

  if (filters) {
    for (const filter of Object.keys(filters)) {
      if (filters[filter]) {
        params[filter] = filters[filter];
      }
    }
  }

  if (page) {
    params.page = page;
  }

  if (pageSize) {
    params.pageSize = pageSize;
  }

  if (order) {
    params.orderBy = order;
  }

  const result: any = await axios.get(`${CONFIG.STATIONS_URL}/admin/import-job-logs`, { params });
  result.data.data = result.data.data.map((l: any) => ({
    ...l,
    createdAt: new Date(l.createdAt),
    updatedAt: new Date(l.updatedAt)
  }));
  return result;
};
