import { Container } from '@material-ui/core';
import { DateRange } from '@material-ui/lab';
import Page from 'components/Page';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { GridState } from '../../@types/grid';
import PickerDateTime from '../../components/b2b/utils/PickerDateTime';
import Grid from '../../components/b2b/weekly';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import { PATH_DASHBOARD } from '../../routes/paths';
import { getCompanies } from '../../api/elton-payments/charging/b2b';

export default function Weekly() {
  // Get query strings
  const path = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const id = params?.id;
  const query = new URLSearchParams(path.search);
  const [value, setValue] = useState<DateRange<Date>>([null, null]);
  const [refresh, setRefresh] = useState<string | undefined>(params?.id);
  const [companies, setCompanies] = useState<string[]>([]);
  const [company, setCompany] = useState<string | undefined>(undefined);

  const findDate = (newValue: any, currency?: string, company?: string) => {
    setValue([newValue[0], newValue[1]]);
    setCompany(company);
  };

  useEffect(() => {
    if (params.id === undefined) {
      getCompanies(1, 100).then((result) => {
        const names = result.data.data.map((b2b: any) => b2b.name);
        setCompanies(names);
      });
      setRefresh('all');
      setValue([null, null]);
    } else {
      setRefresh(params.id);
      setValue([null, null]);
    }
  }, [params?.id]);

  /**
   * Updates query strings
   */
  const updateQueryStrings = (data: GridState) => {
    let queryString = '?';

    // Add filters
    for (const key of Object.keys(data.filters)) {
      if (data.filters[key]) {
        queryString += `${key}=${data.filters[key]}&`;
      }
    }

    if (data.page) {
      queryString += `page=${data.page}&`;
    }
    if (data.pageSize) {
      queryString += `pageSize=${data.pageSize}&`;
    }
    if (data.order) {
      queryString += `orderBy=${data.order}&`;
    }

    navigate({ search: queryString.substring(0, queryString.length - 1) });
  };

  return (
    <Page title="B2B - Report">
      <Container maxWidth="xl">
        <HeaderBreadcrumbs
          heading={`Report, ${refresh}`}
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            { name: 'All companies', href: PATH_DASHBOARD.b2b.companies }
          ]}
        />
        <PickerDateTime
          callback={findDate}
          title="Report search"
          currencySelector={false}
          sentCompanies={companies}
        />
        {value[0] !== null && value[1] !== null ? (
          <Grid
            key={`${refresh}${company}`}
            filters={{
              date_from: value[0],
              date_to: value[1]
            }}
            pages={{
              page: parseInt(query.get('page') || '1', 10),
              pageSize: parseInt(query.get('pageSize') || '100', 10)
            }}
            order={query.get('order') || '-created_at'}
            onUpdate={updateQueryStrings}
            primary={{ id }}
            company_name={company}
            date_from={value[0]}
            date_to={value[1]}
          />
        ) : (
          ''
        )}
      </Container>
    </Page>
  );
}
