import { Card, Stack, TextField } from '@material-ui/core';
import { useEffect, useState } from 'react';

export const GeneralInfoContainer = ({ disabled, currency, onChange, onChangeMany }: any) => {
  const [fields, setFields] = useState<any>({});

  useEffect(() => {
    setFields(currency);
  }, [currency]);

  const handleChange = (
    value: any,
    field: string,
    displayName: string = '',
    displayValue: string = ''
  ) => {
    setFields({
      ...fields,
      [field]: value
    });
    onChange(value, field, displayName, displayValue);
  };

  const handleImport = (currency: any) => {
    if (currency?.currency) {
      const newFields: any = {
        currency: currency.currency,
        country_code: currency?.country_code,
        payment_reservation: currency?.payment_reservation,
        deposit_reservation: currency?.deposit_reservation,
        currency_symbol: currency?.currency_symbol,
        minimum_capture: currency.minimum_capture
      };

      setFields({
        ...fields,
        ...newFields
      });
      onChangeMany(newFields);
    }
  };

  return (
    <Card sx={{ py: 3, px: 3 }}>
      <Stack spacing={3}>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
          <TextField
            fullWidth
            disabled={disabled}
            id="something-like-1"
            label="Currency"
            autoComplete="off"
            value={fields?.currency || ''}
            onChange={(event: any) => handleChange(event.target.value, 'currency')}
          />

          <TextField
            fullWidth
            disabled={disabled}
            id="something-like-1"
            label="Currency symbol"
            autoComplete="off"
            value={fields?.currency_symbol || ''}
            onChange={(event: any) => handleChange(event.target.value, 'currency_symbol')}
          />

          <TextField
            fullWidth
            disabled={disabled}
            id="something-like-1"
            label="Country code"
            autoComplete="off"
            value={fields?.country_code || ''}
            onChange={(event: any) => handleChange(event.target.value, 'country_code')}
          />
        </Stack>

        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
          <TextField
            type="number"
            fullWidth
            disabled={disabled}
            id="something-like-44"
            label="Payment reservation (e.g. VIPPS reservation amount, we reserve 500 NOK inserted as 50000)"
            value={fields?.payment_reservation || ''}
            onChange={(event: any) => handleChange(event.target.value, 'payment_reservation')}
          />
          <TextField
            type="number"
            fullWidth
            disabled={disabled}
            id="something-like-49"
            label="Deposit reservation (e.g. we reserve 70 NOK inserted as 7000)"
            value={fields?.deposit_reservation || ''}
            onChange={(event: any) => handleChange(event.target.value, 'deposit_reservation')}
          />
        </Stack>

        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
          <TextField
            type="number"
            fullWidth
            disabled={disabled}
            id="something-like-64"
            label="Minimum capture in smallest unit (e.g. 100 for 1 NOK)"
            value={fields?.minimum_capture || ''}
            onChange={(event: any) => handleChange(event.target.value, 'minimum_capture')}
          />
        </Stack>
      </Stack>
    </Card>
  );
};
