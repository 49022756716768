import searchFill from '@iconify/icons-eva/search-fill';
import { Icon } from '@iconify/react';
import {
  Box,
  FormControlLabel,
  InputAdornment,
  OutlinedInput,
  Switch,
  Toolbar,
  Typography
} from '@material-ui/core';
// material
import { styled } from '@material-ui/core/styles';

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  gap: '1rem',
  [theme.breakpoints.down('md')]: {
    flexWrap: 'wrap'
  }
}));

const NonbooleanStyle = styled(Toolbar)(({ theme }) => ({
  margin: '1rem',
  display: 'grid',
  gap: '1rem',
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr'
  },
  [theme.breakpoints.up('md')]: {
    gridTemplateColumns: 'repeat(4, minmax(10rem, 1fr))'
  }
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: '100%',
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  '&.Mui-focused': { width: '110%', boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`
  }
}));

const TextFieldFilter = (filter: any) => (
  <SearchStyle
    key={filter.specialId ?? filter.type}
    value={filter.value}
    onChange={(e) => {
      if (filter.onUpdate) {
        filter.onUpdate(e.target.value);
      }
    }}
    placeholder={filter.displayName}
    disabled={filter.disabled !== undefined ? filter.disabled : false}
    startAdornment={
      <InputAdornment position="start">
        <Box component={Icon} icon={filter.icon || searchFill} sx={{ color: 'text.disabled' }} />
      </InputAdornment>
    }
  />
);

const BooleanFieldFilter = (filter: any) => (
  <FormControlLabel
    labelPlacement="start"
    control={
      <Switch
        onChange={(event) => {
          if (filter.onUpdate) {
            filter.onUpdate(event.target.checked);
          }
        }}
        checked={filter.value === true}
      />
    }
    label={
      <>
        <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
          {filter.displayName}
        </Typography>
      </>
    }
  />
);
// ----------------------------------------------------------------------

export default function ListToolbar({
  filters,
  RightNavbarItem
}: {
  filters: any[];
  RightNavbarItem: any;
}) {
  const booleans = filters.filter((it) => it.inputType === 'boolean');
  const notBooleans = filters.filter((it) => it.inputType !== 'boolean');
  return (
    <div>
      <NonbooleanStyle>
        {notBooleans.map((filter: any, index: number) => {
          return <TextFieldFilter key={index} {...filter} />;
        })}
        <RootStyle>
          {booleans.map((filter: any, index: number) => {
            return <BooleanFieldFilter key={index} {...filter} />;
          })}
          {RightNavbarItem && <RightNavbarItem />}
        </RootStyle>
      </NonbooleanStyle>
    </div>
  );
}
