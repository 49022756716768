import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

export default function DeleteDialog({ open, handleClose, handleDelete }: any) {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Are you sure?</DialogTitle>
      <DialogContent sx={{ width: '35rem' }}>
        <DialogContent sx={{ paddingLeft: 0, paddingTop: 1 }}>
          You are about to permanently delete this operator.
        </DialogContent>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="inherit">
          Go back
        </Button>
        <Button color="error" onClick={handleDelete} autoFocus>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}
