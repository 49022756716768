import * as currencies from '@dinero.js/currencies';
import { Chip } from '@material-ui/core';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { dinero, toFormat } from 'dinero.js';
import { transformer } from 'utils/currency';
import { DinteroStatusCodes } from './constants';

const Action = (transaction: any) => {
  let url = '';
  if (transaction?.dintero_transaction_id) {
    const idParts = transaction.dintero_transaction_id.split('.');
    if (idParts.length > 0) {
      url = `https://backoffice.dintero.com/${idParts[0]}/payments/transactions/${transaction?.dintero_transaction_id}`;
    }
  }
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: '100%',
        height: '100%'
      }}
    >
      {transaction?.dintero_transaction_id ? (
        <a style={{ paddingRight: '1rem' }} href={url} target="_blank" rel="noreferrer">
          <img
            style={{ height: 'auto', width: '100%' }}
            src="/static/icons/subscriptions/dintero.svg"
            alt="Map"
          />
        </a>
      ) : (
        ''
      )}
    </div>
  );
};

export const gridColumns: GridColDef[] = [
  {
    field: 'actions',
    headerName: 'Actions',
    description: 'Dintero refrence',
    width: 110,
    sortable: false,
    renderCell: (params: any) => Action(params.value)
  },
  {
    field: 'created_at',
    headerName: 'Date',
    width: 170,
    renderCell: (params: any) => (params.value ? params.value.toLocaleString() : '')
  },
  {
    field: 'valid_until',
    headerName: 'Sub. valid until',
    description: 'Subscription will be valid until this date.',
    width: 200,
    renderCell: (params: any) => (params.value ? params.value.toLocaleString() : '')
  },
  { field: 'user_id', headerName: 'User Id', width: 330 },
  { field: 'firebase_id', headerName: 'Firebase User Id', width: 350 },
  {
    field: 'status',
    headerName: 'Status',
    width: 160,
    renderCell: (params: GridRenderCellParams) => {
      if (params.value) {
        let status: any = {
          displayValue: params.value as string,
          color: 'error',
          variant: 'outlined'
        };
        if (Object.keys(DinteroStatusCodes).includes(status.displayValue)) {
          status = DinteroStatusCodes[status.displayValue];
        }
        return <Chip label={status.displayValue} color={status.color} variant={status.variant} />;
      }
      return '';
    }
  },
  {
    field: 'amount_incl_vat',
    headerName: 'Amount inc VAT',
    width: 200,
    type: 'number',
    renderCell: (params: GridRenderCellParams) => {
      const value = dinero({
        amount: parseFloat(params.value as any),
        currency: (currencies as any)[
          Object.keys(currencies).includes(params.row?.currency) ? params.row?.currency : 'NOK'
        ]
      });
      return <p>{params.value ? `${toFormat(value, transformer)}` : ''}</p>;
    }
  },
  {
    field: 'amount_excl_vat',
    headerName: 'Amount excl VAT',
    width: 200,
    type: 'number',
    renderCell: (params: GridRenderCellParams) => {
      const value = dinero({
        amount: parseFloat(params.value as any),
        currency: (currencies as any)[
          Object.keys(currencies).includes(params.row?.currency) ? params.row?.currency : 'NOK'
        ]
      });
      return <p>{params.value ? `${toFormat(value, transformer)}` : ''}</p>;
    }
  },
  {
    field: 'vat',
    headerName: 'VAT',
    width: 120,
    type: 'number',
    renderCell: (params: GridRenderCellParams) => (
      <p>{params.value ? `${parseFloat(params.value as any)}%` : ''}</p>
    )
  },
  {
    field: 'updated_at',
    headerName: 'Updated At',
    width: 170,
    renderCell: (params: any) => (params.value ? params.value.toLocaleString() : '')
  },
  { field: 'id', headerName: 'Id', width: 330 },
  {
    field: 'contract_id',
    headerName: 'Contract Id',
    width: 330
  }
];
