import { FormControlLabel, Switch, Typography } from '@material-ui/core';

export const SwitchWithLabel = ({ title, description, value, onChange, disabled, type }: any) => (
  <FormControlLabel
    labelPlacement="start"
    control={
      <Switch
        disabled={disabled}
        onChange={(event) =>
          onChange(event.target.checked, type, title, event.target.checked ? 'True' : 'False')
        }
        checked={value === true}
      />
    }
    label={
      <>
        <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
          {title} {value === null && '*'}
        </Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {description}
        </Typography>
      </>
    }
    sx={{ mx: 0, mb: 3, width: 1, justifyContent: 'space-between' }}
  />
);
