import { BasicGridFilter, BasicGridTransformedFilters } from '../../../@types/grid';

export const getFilters = (filters: BasicGridTransformedFilters): BasicGridFilter[] => [
  {
    type: 'user_id',
    displayName: 'User Id',
    value: filters?.user_id || '',
    icon: 'ant-design:user-outlined'
  },
  {
    type: 'subscription_id',
    displayName: 'Subscription Id',
    value: filters?.subscription_id || '',
    icon: 'eos-icons:product-subscriptions'
  }
];
