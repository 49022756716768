// material
import { styled, useTheme } from '@material-ui/core/styles';
import LoadingScreen from 'components/LoadingScreen';
import Contracts from 'pages/subscriptions/Contracts';
import Transactions from 'pages/subscriptions/Transactions';
import { lazy, Suspense, useState } from 'react';
// hooks
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
import Weekly from '../../pages/b2b/Weekly';
import ViewSession from '../../pages/charging/ViewSessionDetails';
import EditCountry from '../../pages/countries/EditCountry';
import ListCountries from '../../pages/countries/ListCountries';
import ImportJobs from '../../pages/locations/logs/ImportJobs';
import EditOperator from '../../pages/operators/EditOperator';
import ListOperators from '../../pages/operators/ListOperators';
import ContractEdit from '../../pages/subscriptions/ContractEdit';
//
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';
import ListCurrencies from '../../pages/currencies/ListCurrencies';
import EditCurrency from '../../pages/currencies/EditCurrency';
import Plans from '../../pages/b2b/Plans';

// ----------------------------------------------------------------------

export const Loadable = (Component: any) => (props: any) => {
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

const Offers = Loadable(lazy(() => import('../../pages/subscriptions/Offers')));
const EditOffer = Loadable(lazy(() => import('../../pages/subscriptions/OfferEdit')));
const Analytics = Loadable(lazy(() => import('../../pages/Analytics')));
const Revenues = Loadable(lazy(() => import('../../pages/Revenues')));
const Conversion = Loadable(lazy(() => import('../../pages/Conversion')));
const Charging = Loadable(lazy(() => import('../../pages/Charging')));
const LocationsMap = Loadable(lazy(() => import('../../pages/locations/LocationsMap')));
const LocationEdit = Loadable(lazy(() => import('../../pages/locations/LocationEdit')));
const LocationList = Loadable(lazy(() => import('../../pages/locations/LocationList')));
const CompaniesList = Loadable(lazy(() => import('../../pages/b2b/Companies')));
const VoucherCompaniesList = Loadable(lazy(() => import('../../pages/voucher/Companies')));
const CompanySubscriptionVouchers = Loadable(
  lazy(() => import('../../pages/voucher/Subscriptions'))
);
const VoucherUserList = Loadable(lazy(() => import('../../pages/voucher/Vouchers')));
const Partners = Loadable(lazy(() => import('../../pages/partners/Partners')));
const PartnerDetails = Loadable(lazy(() => import('../../pages/partners/PartnerDetails')));
const WhitelistedList = Loadable(lazy(() => import('../../pages/b2b/Whitelisted')));
const Invoices = Loadable(lazy(() => import('../../pages/b2b/Invoices')));
const StatusList = Loadable(lazy(() => import('../../pages/charging/StatusList')));
const UserDetails = Loadable(lazy(() => import('../../pages/user/UserDetails')));
const Sessions = Loadable(lazy(() => import('../../pages/charging/Sessions')));
const FinancialReports = Loadable(lazy(() => import('../../pages/financials/FinancialReports')));
const LoyaltyUserAdmin = Loadable(lazy(() => import('../../pages/loyalty/userAdmin')));
const LoyaltyTiers = Loadable(lazy(() => import('../../pages/loyalty/tiers')));

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const theme = useTheme();
  const { collapseClick } = useCollapseDrawer();
  const [open, setOpen] = useState(false);
  const [fullScreen, setFullScreen] = useState(false);

  return (
    <RootStyle>
      {!fullScreen && (
        <>
          <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
          <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
        </>
      )}
      <MainStyle
        style={{ paddingTop: fullScreen ? '2rem' : '116px' }}
        sx={{
          transition: theme.transitions.create('margin', {
            duration: theme.transitions.duration.complex
          }),
          ...(collapseClick && {
            ml: '102px'
          })
        }}
      >
        <Routes>
          <Route
            path="analytics"
            element={<Analytics fullScreen={fullScreen} setFullScreen={setFullScreen} />}
          />
          <Route
            path="analytics/:id"
            element={<Analytics fullScreen={fullScreen} setFullScreen={setFullScreen} />}
          />
          <Route
            path="revenues/:id"
            element={<Revenues fullScreen={fullScreen} setFullScreen={setFullScreen} />}
          />
          <Route
            path="revenues"
            element={<Revenues fullScreen={fullScreen} setFullScreen={setFullScreen} />}
          />
          <Route
            path="conversion"
            element={<Conversion fullScreen={fullScreen} setFullScreen={setFullScreen} />}
          />
          <Route
            path="conversion/:id"
            element={<Conversion fullScreen={fullScreen} setFullScreen={setFullScreen} />}
          />
          <Route
            path="charging"
            element={<Charging fullScreen={fullScreen} setFullScreen={setFullScreen} />}
          />
          <Route
            path="charging/:id"
            element={<Charging fullScreen={fullScreen} setFullScreen={setFullScreen} />}
          />
          <Route path="operators/edit" element={<EditOperator />} />
          <Route path="operators/edit/:id" element={<EditOperator />} />
          <Route path="operators" element={<ListOperators />} />
          <Route path="countries/edit" element={<EditCountry />} />
          <Route path="countries/edit/:id" element={<EditCountry />} />
          <Route path="countries" element={<ListCountries />} />
          <Route path="currencies/edit" element={<EditCurrency />} />
          <Route path="currencies/edit/:id" element={<EditCurrency />} />
          <Route path="currencies" element={<ListCurrencies />} />
          <Route path="subscriptions/offers/edit/:id" element={<EditOffer />} />
          <Route path="subscriptions/offers" element={<Offers />} />
          <Route path="subscriptions/contracts/:id" element={<ContractEdit />} />
          <Route path="subscriptions/contracts" element={<Contracts />} />
          <Route path="b2b/whitelisted/:id" element={<WhitelistedList />} />
          <Route path="b2b/companies" element={<CompaniesList />} />
          <Route path="b2b/invoices" element={<Invoices />} />
          <Route path="b2b/report/:id" element={<Weekly />} />
          <Route path="b2b/report" element={<Weekly />} />
          <Route path="b2b/plans" element={<Plans />} />
          <Route path="voucher/companies" element={<VoucherCompaniesList />} />
          <Route path="voucher/users/:id" element={<VoucherUserList />} />
          <Route path="voucher/subscriptions/:id" element={<CompanySubscriptionVouchers />} />
          <Route path="partners" element={<Partners />} />
          <Route path="partners/:id" element={<PartnerDetails />} />
          <Route path="user/get" element={<UserDetails />} />
          <Route path="financial/reports" element={<FinancialReports />} />
          <Route path="charging/status/:type" element={<StatusList />} />
          <Route path="charging/sessions/:id" element={<ViewSession />} />
          <Route path="charging/sessions" element={<Sessions />} />
          <Route path="subscriptions/transactions" element={<Transactions />} />
          <Route path="locations/edit" element={<LocationEdit />} />
          <Route path="locations/find" element={<LocationList />} />
          <Route path="locations/map" element={<LocationsMap />} />
          <Route path="locations/logs/import-jobs" element={<ImportJobs />} />
          <Route path={'loyalty/user'} element={<LoyaltyUserAdmin />} />
          <Route path={'loyalty/tiers'} element={<LoyaltyTiers />} />
          <Route path="" element={<Navigate to="analytics" />} />
        </Routes>
      </MainStyle>
    </RootStyle>
  );
}
