import Fill from "@iconify/icons-eva/home-fill";
import { Icon } from "@iconify/react";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import {
    Avatar,
    Box,
    Button,
    Divider,
    MenuItem,
    Typography,
} from "@material-ui/core";
import { alpha } from "@material-ui/core/styles";
import Stack from "@mui/material/Stack/Stack";
import { UserContext } from "contexts/UserContext";
import React, { useRef, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { MIconButton } from "../../components/@material-extend";
import MenuPopover from "../../components/MenuPopover";
import SettingThemeMode from "../../components/settings/SettingThemeMode";

const MENU_OPTIONS = [{ label: "Home", icon: Fill, linkTo: "/" }];

export default function AccountPopover() {
    const anchorRef = useRef(null);
    const [open, setOpen] = useState(false);
    const [{ user }]: any = React.useContext(UserContext);

    const { logout } = useKindeAuth();

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <MIconButton
                ref={anchorRef}
                onClick={handleOpen}
                sx={{
                    padding: 0,
                    width: 44,
                    height: 44,
                    ...(open && {
                        "&:before": {
                            zIndex: 1,
                            content: "''",
                            width: "100%",
                            height: "100%",
                            borderRadius: "50%",
                            position: "absolute",
                            bgcolor: (theme) =>
                                alpha(theme.palette.grey[900], 0.72),
                        },
                    }),
                }}
            >
                <Avatar
                    alt="Profile image"
                    src={
                        user.profile ||
                        "/static/mock-images/avatars/avatar_default.jpg"
                    }
                />
            </MIconButton>

            <MenuPopover
                open={open}
                onClose={handleClose}
                anchorEl={anchorRef.current}
                sx={{ width: 220 }}
            >
                <Box sx={{ my: 1.5, px: 2.5 }}>
                    <Typography variant="subtitle1" noWrap>
                        {user.name}
                    </Typography>
                    <Typography
                        variant="body2"
                        sx={{ color: "text.secondary" }}
                        noWrap
                    >
                        {user.email}
                    </Typography>
                </Box>

                <Divider sx={{ my: 1 }} />

                {MENU_OPTIONS.map((option) => (
                    <MenuItem
                        key={option.label}
                        to={option.linkTo}
                        component={RouterLink}
                        onClick={handleClose}
                        sx={{ typography: "body2", py: 1, px: 2.5 }}
                    >
                        <Box
                            component={Icon}
                            icon={option.icon}
                            sx={{
                                mr: 2,
                                width: 24,
                                height: 24,
                            }}
                        />

                        {option.label}
                    </MenuItem>
                ))}

                <Divider sx={{ my: 1 }} />

                <Stack style={{ padding: "1rem" }} spacing={1.5}>
                    <SettingThemeMode />
                </Stack>
                <Divider sx={{ my: 1 }} />

                <Box sx={{ p: 2, pt: 1.5 }}>
                    <Button
                        fullWidth
                        color="inherit"
                        variant="outlined"
                        onClick={async () => await logout()}
                    >
                        Log out
                    </Button>
                </Box>
            </MenuPopover>
        </>
    );
}
