import React from 'react';
import { userReducer, initialState } from '../reducers/UserReducer';

export const UserContext = React.createContext({
  state: initialState,
  dispatch: () => null
});

export const UserProvider = ({ children }: any) => {
  const reducer: any = React.useReducer(userReducer, initialState);

  return <UserContext.Provider value={reducer}>{children}</UserContext.Provider>;
};
