// ----------------------------------------------------------------------

const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 900,
    lg: 1920,
    xl: 2560
  }
};

export default breakpoints;
