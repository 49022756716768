import { LocationListFilter } from '../../../@types/locations';
import axios from '../../../utils/axios';
import {CONFIG} from "../../../config";

/**
 * Get all offers
 * @param page
 * @param pageSize
 * @param order
 * @param filters
 * @returns
 */
export const getOffers = async (
  page: number,
  pageSize: number = 25,
  order: any = { field: 'name', sort: 'asc' },
  filters: Partial<LocationListFilter>[] = []
) => {
  const params: any = {};

  if (page) {
    params.page = page;
  }

  if (pageSize) {
    params.pageSize = pageSize;
  }

  if (order) {
    params.order_by = order.sort === 'desc' ? `-${order.field}` : order.field;
  }

  for (const filter of filters) {
    params[filter?.type as any] = filter.value;
  }
  return axios.get(`${CONFIG.SERVICES_ADMIN_URL}/admin/v1/subscriptions`, { params });
};

/**
 * Get a single offer
 * @param id
 * @returns
 */
export const getOffer = async (id: string) => axios.get(`${CONFIG.SERVICES_ADMIN_URL}/admin/v1/subscriptions/${id}`);

/**
 * Create a single new offer
 * @param offer
 * @returns
 */
export const createOffer = async (offer: any) =>
  axios.post(`${CONFIG.SERVICES_ADMIN_URL}/admin/v1/subscriptions`, offer);

/**
 * Update existing offer
 * @param id
 * @param offer
 * @returns
 */
export const updateOffer = async (id: string, offer: any) =>
  axios.put(`${CONFIG.SERVICES_ADMIN_URL}/admin/v1/subscriptions/${id}`, offer);

/**
 * delete offer
 * @param id
 * @param offer
 * @returns
 */
export const destoryOffer = async (id: string) =>
  axios.delete(`${CONFIG.SERVICES_ADMIN_URL}/admin/v1/subscriptions/${id}`);

/**
 * Get prices for a given offer
 * @param id
 * @param page
 * @param pageSize
 * @param order
 * @returns
 */
export const getPrices = async (
  id: string,
  page: number,
  pageSize: number = 25,
  order: any = { field: 'name', sort: 'asc' }
) => {
  const params: any = {};

  if (page) {
    params.page = page;
  }

  if (pageSize) {
    params.page_size = pageSize;
  }

  if (order) {
    params.order_by = order.sort === 'desc' ? `-${order.field}` : order.field;
  }

  return axios.get(`${CONFIG.SERVICES_ADMIN_URL}/admin/v1/subscriptions/${id}/prices`, { params });
};

/**
 * Create a single new price row
 * @returns
 * @param subId
 * @param price
 */
export const createPrice = async (subId: string, price: any) =>
  axios.post(`${CONFIG.SERVICES_ADMIN_URL}/admin/v1/subscriptions/${subId}/prices`, price);

/**
 * Delete a single price row
 * @returns
 * @param subId
 * @param priceId
 * @param price
 */
export const updatePrice = async (subId: string, priceId: any, price: any) =>
  axios.put(`${CONFIG.SERVICES_ADMIN_URL}/admin/v1/subscriptions/${subId}/prices/${priceId}`, price);

/**
 * Delete a single price row
 * @returns
 * @param subId
 * @param priceId
 */
export const destoryPrice = async (subId: string, priceId: string) =>
  axios.delete(`${CONFIG.SERVICES_ADMIN_URL}/admin/v1/subscriptions/${subId}/prices/${priceId}`);
