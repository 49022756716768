
import { Autocomplete, CircularProgress, TextField } from '@material-ui/core';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { useCallback, useEffect, useState } from 'react';
import { getCountriesv2 } from '../../api/elton-payments/country';

export const CountryAutoComplete = ({ onChange, label, id, disabled, useSimple }: any) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [selected, setSelected] = useState<any>(null);
  const [countries, setCountries]: any = useState<any[]>([]);

  useEffect(() => {
    (async () => {
      // Only do update on change
      if (selected && selected?.id === id) {
        return;
      }

      // If a given id is provided load a specific operator, otherwise load first 25
      const countries = (await getCountriesv2(1, 25, null, { name: selected?.name })).data.data;

      setCountries(countries);
      if (id) {
        const country = countries.find((c: any) => c.primary_id === id);
        setSelected(country);
      }
    })();
  }, [selected, id]);

  // Do Search after 600ms
  //const fetchCountriesDebounce = useCallback(AwesomeDebouncePromise(getCountriesv2, 600), []);

  const handleUpdate = async (e: any) => {
    setLoading(true);
    const result = (await getCountriesv2(1, 25, null, { name: selected?.name })).data.data;
    if (result) {
      setCountries(result);
      setLoading(false);
    }
  };

  /**
   * Update state when user selects a given operator
   * @param value
   */
  const handleSelect = async (value: any) => {
    setSelected(value);
    onChange(value || {});
  };

  return (
    <Autocomplete
      fullWidth
      disabled={disabled}
      openOnFocus={true}
      id={`${id}` || 'some-random-country-id'}
      isOptionEqualToValue={(option: any, value: any) =>
        option && value && (option?.id === value?.id || option?.primary_id === value?.primary_id)
      }
      getOptionLabel={(option: any) => `${option.flag} ${option.name}`}
      options={countries}
      loading={loading}
      value={selected}
      onChange={(e: any, value: any) => handleSelect(value)}
      renderInput={(params) => (
        <TextField
          {...params}
          autoComplete="off"
          onChange={handleUpdate}
          label={useSimple ? '' : label || 'Country'}
          InputProps={{
            ...params.InputProps,
            autoComplete: 'new-password',
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            )
          }}
        />
      )}
    />
  );
};
