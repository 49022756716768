import { Icon } from '@iconify/react';
import {
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@material-ui/core';
import Card from '@material-ui/core/Card';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import React, { useCallback, useState } from 'react';

export const Filters = (props: any) => {

  const updateElements = useCallback(AwesomeDebouncePromise(props.setElements, 600), []);

  const [filters, setFilters] = useState<any>({
    external_price_id: '',
    min_power: '',
    max_power: ''
  });
  const [order, setOrder] = useState<any>('created_at');

  const handleFilterChange = (event: any, filterName: string) => {
    const newFilters = { ...filters, [filterName]: event.target.value };
    setFilters(newFilters);
    updateElements({ ...newFilters, order_by: order });
  };

  const handleOrderChange = (event: any) => {
    setOrder(event.target.value);
    props.setElements({ filters, order_by: event.target.value });
  };

  return (
    <div>
      <Card
        sx={{
          py: 3,
          px: 3,
          background: '#F4F6F8',
          overflow: 'visible',
          marginTop: '1rem',
          border: '1px solid #F4F6F8'
        }}
      >
        <Typography
          style={{ position: 'absolute', top: '-0.8rem', left: '1rem', color: '#919EAB' }}
        >
          Tariff Filter Options
        </Typography>
        <TextField
          style={{ marginRight: '1rem', background: 'white', width: '20rem' }}
          variant="outlined"
          label="External Price Id"
          value={filters.external_price_id}
          onChange={(e) => handleFilterChange(e, 'external_price_id')}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Icon icon="akar-icons:key" />
              </InputAdornment>
            )
          }}
        />

        {/* <TextField
          style={{ marginRight: '1rem', background: 'white', width: '10rem' }}
          variant="outlined"
          label="Min power"
          value={filters.min_power}
          onChange={(e) => handleFilterChange(e, 'min_power')}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Icon icon="bi:lightning-charge" />
              </InputAdornment>
            )
          }}
        />
        <TextField
          style={{ marginRight: '1rem', background: 'white', width: '10rem' }}
          variant="outlined"
          label="Max power"
          value={filters.max_power}
          onChange={(e) => handleFilterChange(e, 'max_power')}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Icon icon="bi:lightning-charge-fill" />
              </InputAdornment>
            )
          }}
        /> */}
        <FormControl style={{ float: 'right', width: '14rem', background: 'white' }}>
          <InputLabel>Sort By</InputLabel>
          <Select onChange={handleOrderChange} value={order} label="Order">
            <MenuItem value="created_at">Created at (asc)</MenuItem>
            <MenuItem value="-created_at">Created at (desc)</MenuItem>
            <MenuItem value="min_duration">Min charge duration (asc)</MenuItem>
            <MenuItem value="-min_duration">Min charge duration (desc)</MenuItem>
            <MenuItem value="start_time">Time of day (asc)</MenuItem>
            <MenuItem value="-start_time">Time of day (desc)</MenuItem>
          </Select>
        </FormControl>
      </Card>
    </div>
  );
};
