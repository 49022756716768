import axios from '../../../utils/axios';
import { BasicGridTransformedFilters } from '../../../@types/grid';
import { CompanyDto, Plan } from '../../../@types/b2b/b2b';
import {CONFIG} from "../../../config";

/**
 * Get all contracts
 * @param page
 * @param pageSize
 * @param order
 * @param filters
 * @returns
 */
export const getCompanies = async (
  page: number = 1,
  pageSize: number = 25,
  order: string | null = null,
  filters: BasicGridTransformedFilters | null = {}
) => {
  const params: any = {};
  if (filters) {
    for (const filter of Object.keys(filters)) {
      if (filters[filter]) {
        params[filter] = filters[filter];
      }
    }
  }

  if (page) {
    params.page = page;
  }

  if (pageSize) {
    params.page_size = pageSize;
  }

  if (order) {
    params.order_by = order;
  }

  const response = await axios.get(`${CONFIG.SERVICES_ADMIN_URL}/api/v1/admin/b2b/companies/list`, { params });
  // Map date to js dates
  response.data.data = response.data.data.map((company: any) => ({
    ...company,
    created_at: new Date(company.created_at)
  }));
  return response;
};

export const getWhitelisted = async (
  id: string,
  page: number = 1,
  pageSize: number = 25,
  order: string | null = null,
  filters: BasicGridTransformedFilters | null = {}
) => {
  const params: any = {};
  if (filters) {
    for (const filter of Object.keys(filters)) {
      if (filters[filter]) {
        params[filter] = filters[filter];
      }
    }
  }

  if (page) {
    params.page = page;
  }

  if (pageSize) {
    params.page_size = pageSize;
  }

  if (order) {
    params.order_by = order;
  }

  const response = await axios.get(`${CONFIG.SERVICES_ADMIN_URL}/api/v1/admin/b2b/whitelisted/company/${id}`, {
    params
  });

  // Map date to js dates
  response.data.data = response.data.data.map((whitelisted: any) => ({
    ...whitelisted,
    created_at: new Date(whitelisted.created_at)
  }));
  return response;
};

export const getWhitelistedAudit = async (
  whitelistedId: string
): Promise<{ created_at: string; content: string }[]> => {
  const response = await axios.get(`${CONFIG.SERVICES_ADMIN_URL}/api/v1/admin/b2b/audit/${whitelistedId}`);
  return response.data;
};

export const getCompany = async (id: string) => {
  const response = await axios.get(`${CONFIG.SERVICES_ADMIN_URL}/api/v1/admin/b2b/company/${id}`);
  return response;
};

export const updateCompany = async (id: string, company: any) => {
  const response = await axios.put(`${CONFIG.SERVICES_ADMIN_URL}/api/v1/admin/b2b/company/${id}`, { ...company });
  return response;
};

export const updateWhitelisted = async (id: string, identifier: string) => {
  const response = await axios.put(`${CONFIG.SERVICES_ADMIN_URL}/api/v1/admin/b2b/whitelisted/${id}`, {
    identifier
  });
  return response;
};

export const addWhitelisted = async (identifier: string, company: CompanyDto) => {
  const response = await axios.post(`${CONFIG.SERVICES_ADMIN_URL}/api/v1/admin/b2b/whitelisted/`, {
    company_name: company.company_name,
    company_id: company.id,
    whitelisted: [
      {
        identifier
      }
    ]
  });
  return response;
};

export const registerCompany = async (company: any) => {
  const response = await axios.post(`${CONFIG.SERVICES_ADMIN_URL}/api/v1/admin/b2b/company`, { ...company });
  return response;
};

export const deleteCompany = async (id: string) => {
  const response = await axios.delete(`${CONFIG.SERVICES_ADMIN_URL}/api/v1/admin/b2b/company/${id}`);
  return response;
};

export const deleteWhitelisted = async (id: string, name: string, identifier: string) => {
  const response = await axios.post(`${CONFIG.SERVICES_ADMIN_URL}/api/v1/admin/b2b/whitelisted/delete`, {
    company_name: name,
    company_id: id,
    id: identifier
  });
  return response;
};

export const reactivateWhitelisted = async (id: string, name: string, identifier: string) => {
  const response = await axios.post(`${CONFIG.SERVICES_ADMIN_URL}/api/v1/admin/b2b/whitelisted/reactivate`, {
    company_name: name,
    company_id: id,
    id: identifier
  });
  return response;
};

export const getAllPlans = async (): Promise<Plan[]> => {
  const res = await axios.get(`${CONFIG.SERVICES_ADMIN_URL}/api/v1/admin/b2b/plan`);
  return res.data;
};

export const createPlan = async (plan: Plan): Promise<Plan> => {
  const res = await axios.post(`${CONFIG.SERVICES_ADMIN_URL}/api/v1/admin/b2b/plan`, { name: plan.name });
  return res.data;
};

export const updatePlan = async (plan: Plan): Promise<Plan> => {
  const res = await axios.put(`${CONFIG.SERVICES_ADMIN_URL}/api/v1/admin/b2b/plan`, {
    primary_id: plan.primary_id,
    name: plan.name
  });
  return res.data;
};
