// material
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField
} from '@material-ui/core';
import AddIcon from '@mui/icons-material/Add';
import { useState } from 'react';
import { Plan } from '../../../@types/b2b/b2b';
import { createPlan, updatePlan } from '../../../api/elton-payments/charging/b2b';

// ----------------------------------------------------------------------

type PlanEditFormProps = {
  isEdit: boolean;
  inputPlan?: Plan;
};

export default function FormDialogs({ isEdit, inputPlan }: PlanEditFormProps) {
  let [open, setOpen] = useState<boolean>(false);
  let [plan, setPlan] = useState<Plan | undefined>(inputPlan);

  const handleClickOpen = () => {
    if (inputPlan != null) {
      setPlan(inputPlan);
    }
    setOpen(true);
  };

  const handleClose = () => {
    setPlan(undefined);
    setOpen(false);
  };

  const onChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPlan({ ...plan, name: e.target.value } as Plan);
  };

  const handleSave = async () => {
    // if the plan has an ID, update it
    if (isEdit && plan?.primary_id != null) {
      await updatePlan(plan);
      handleClose();
    } else if (plan != null) {
      await createPlan(plan);
      handleClose();
    }
  };

  return (
    <div>
      <Button
        variant="outlined"
        color="success"
        onClick={handleClickOpen}
        style={!isEdit ? { width: '3.5rem', height: '3.5rem' } : undefined}
      >
        {!isEdit ? <AddIcon /> : 'Edit'}
      </Button>

      <Dialog open={open} onClose={handleClose}>
        {
          <div>
            <DialogTitle>Create/edit plan</DialogTitle>
            <DialogContent>
              <DialogContentText>
                {!isEdit
                  ? 'To add a new plan fill out the information below:'
                  : 'Edit plan information below:'}
              </DialogContentText>
              <TextField
                autoFocus
                fullWidth
                type="text"
                margin="dense"
                variant="outlined"
                label="Name"
                onChange={onChangeName}
                value={plan?.name ?? ''}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="inherit">
                Cancel
              </Button>
              <Button onClick={handleSave} variant="contained">
                {isEdit ? 'Save' : 'Add'}
              </Button>
            </DialogActions>
          </div>
        }
      </Dialog>
    </div>
  );
}
