import * as currencies from '@dinero.js/currencies';
import { dinero, toFormat } from 'dinero.js';

/**
 * Add spaces to large numbers
 * From https://stackoverflow.com/questions/16637051/adding-space-between-numbers
 * @param x
 * @returns
 */
const numberWithSpaces = (x: any) => {
  const parts = x.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  return parts.join('.');
};

/**
 * Transform Dinero currency
 * @param param0
 * @returns
 */
export const transformer = ({ amount, currency }: { amount: any; currency: any }) =>
  `${currency.code} ${numberWithSpaces(amount)}`;

/**
 * Transform Dinero currency
 * @param param0
 * @returns
 */
export const transformerAfter = ({ amount, currency }: { amount: any; currency: any }) =>
  `${numberWithSpaces(amount)} ${currency.code}`;

/**
 * Convert amount to string friendly version
 * @param amount
 * @param currency
 * @returns
 */
export const transformAmount = (
  amount: number,
  currency: string,
  currencyAfter = false
): string => {
  if (Number.isInteger(amount)) {
    const value = dinero({
      amount,
      currency: (currencies as any)[Object.keys(currencies).includes(currency) ? currency : 'NOK']
    });
    if (currencyAfter) {
      return toFormat(value, transformerAfter);
    }
    return toFormat(value, transformer);
  }
  return 'NaN';
};
