import { Alert } from '@material-ui/core';
import { Snackbar } from '@mui/material';
import { useState } from 'react';
import LoadingScreen from '../../LoadingScreen';
import { EditOperator } from './EditOperator';
import { Prices } from './tariff/Prices';

export function Edit({ disabled, id, setId }: any) {
  const [operator, setOperator] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(true);
  const [message, setMessage] = useState<{
    show: boolean;
    message: string;
    type: 'success' | 'error';
  }>({
    show: false,
    message: '',
    type: 'success'
  });

  return (
    <div>
      {loading && (
        <div
          style={{
            height: 'calc(100vh - 200px)',
            width: 'calc(100vw - 280px)',
            background: 'white',
            position: 'fixed',
            top: '200px',
            left: '280px',
            zIndex: 1000
          }}
        >
          <LoadingScreen />
        </div>
      )}
      <div>
        <EditOperator
          id={id}
          setId={(id: string) => setId(id)}
          disabled={disabled}
          setLoading={setLoading}
          setMessage={setMessage}
          setParentOperator={setOperator}
        />
        <Prices
          disabled={disabled}
          operatorId={id}
          setMessage={setMessage}
          setLoading={setLoading}
          operator={operator}
        />
      </div>
      <Snackbar
        open={message.show}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        onClose={() => setMessage({ ...message, show: false })}
      >
        <Alert
          onClose={() => setMessage({ ...message, show: false })}
          variant="outlined"
          severity={message.type}
          sx={{ width: '100%', backgroundColor: 'white' }}
        >
          {message.message}
        </Alert>
      </Snackbar>
    </div>
  );
}
