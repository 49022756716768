// material
import { Box, BoxProps } from '@material-ui/core';

// ----------------------------------------------------------------------

export default function Logo({ sx }: BoxProps) {
  return (
    <Box sx={{ ...sx }}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="24.0011" cy="24.0013" r="22.0011" fill="#3BA898" />
        <path
          d="M11.1705 25.2627L24.4949 19.3057C25.6974 18.7681 27.0015 19.7681 27.0015 21.2277L27.0015 32.6345C27.0015 34.0682 25.7398 35.0666 24.5454 34.5781L11.221 29.1282C9.63589 28.4799 9.60302 25.9634 11.1705 25.2627Z"
          fill="white"
        />
        <path
          d="M36.8324 17.5608L23.508 11.6038C22.3056 11.0662 21.0015 12.0662 21.0015 13.5258L21.0015 24.9326C21.0015 26.3663 22.2631 27.3647 23.4575 26.8762L36.7819 21.4263C38.367 20.778 38.3999 18.2615 36.8324 17.5608Z"
          fill="white"
        />
        <path
          d="M21.0015 45.7996V32.1302C21.0015 30.4025 22.3447 29.0018 24.0017 29.0018C25.6586 29.0018 27.0018 30.4024 27.0018 32.1302V45.7995C26.0208 45.9333 25.0192 46.0024 24.0014 46.0024C22.9838 46.0024 21.9824 45.9333 21.0015 45.7996Z"
          fill="white"
        />
        <path opacity="0.3" d="M21.0015 33.1351V35.5911H27.0018L21.0015 33.1351Z" fill="#3BA898" />
      </svg>
    </Box>
  );
}
