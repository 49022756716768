import { Alert, Grid } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { LocalizationProvider } from '@material-ui/lab';
import { Button, FormControl, Snackbar } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import {
  createContract,
  getContract,
  updateContract
} from '../../../../api/elton-payments/subscriptions/contracts';
import LoadingScreen from '../../../LoadingScreen';
import { BooleanContainer } from './BooleanContainer';
import { GeneralInfoContainer } from './GeneralInfoContainer';
import SaveDialog from './SaveDialog';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

const StyledButton = styled(Button)(({ theme }) => ({
  marginLeft: '1rem',
  height: '3rem',
  [theme.breakpoints.down('md')]: {
    margin: '2px',
    width: '100%'
  }
}));

export function Edit({ disabled, id, setId }: any) {
  const [loading, setLoading] = useState<boolean>(true);
  const [saveDialog, setSaveDialog] = useState<boolean>(false);
  const [contract, setContract] = useState<any>({});
  const [message, setMessage] = useState<{
    show: boolean;
    message: string;
    type: 'success' | 'error';
  }>({
    show: false,
    message: '',
    type: 'success'
  });
  const [changedContractFields, setChangedContractFields] = useState<any[]>([]);

  const fetchContract = useCallback(
    async (newId: string) => {
      if (newId) {
        setLoading(true);
        const result = await getContract(newId);
        setContract(result?.data);
        setChangedContractFields([]);
        setLoading(false);
      } else {
        setLoading(false);
      }
    },
    [setContract, setLoading]
  );

  /**
   * Fetch mew contract on id change
   */
  useEffect(() => {
    if (id !== 'new') {
      fetchContract(id);
    } else {
      setContract({});
      setChangedContractFields([]);
      setLoading(false);
    }
  }, [fetchContract, id]);

  /**
   * Handle input change, and update changedFields
   * @param value
   * @param field
   * @param displayName
   * @param displayValue
   */
  const handleChange = useCallback(
    (value: any, field: string, displayName: string = '', displayValue: any = '') => {
      const change = { value, field, displayName, displayValue };
      setChangedContractFields([...changedContractFields.filter((l) => l.field !== field), change]);
    },
    [changedContractFields, setChangedContractFields]
  );

  /**
   * Reset state
   */
  const handleDiscard = () => {
    setContract(contract);
    setChangedContractFields([]);
  };

  /**
   * Save changedFields
   */
  const handleSave = async () => {
    // Close dialog
    setSaveDialog(false);

    // Change format to object
    const update: any = {};
    for (const change of changedContractFields) {
      update[change.field] = change.value?.length === 0 ? null : change.value;
    }

    try {
      if (id === 'new') {
        const response = await createContract(update);
        setId(response.data.id);
      } else {
        await updateContract(contract.id, update);
        // Reset view
        setChangedContractFields([]);

        // Show Alert
        setMessage({ show: true, message: 'contract updated!', type: 'success' });

        // Fetch new contract
        await fetchContract(id);
      }
    } catch (error: any) {
      console.error(error);
      let message = 'Unknown Error';
      if (error?.status_message) {
        message = error.status_message;
      } else if (error?.message) {
        message = error?.message;
      }
      setMessage({ show: true, message, type: 'error' });
    }
  };

  return loading ? (
    <div style={{ height: 'calc(100vh - 320px)' }}>
      <LoadingScreen sx={{}} />
    </div>
  ) : (
    <div>
      <FormControl>
        <Grid container spacing={3}>
          {/* Search field to find contract */}
          <Grid item xs={12} md={4} />

          {/* Fake row to force new line */}
          <Grid style={{ display: 'flex', justifyContent: 'flex-end' }} item xs={12} md={8}>
            <StyledButton
              disabled={!(changedContractFields.length > 0)}
              variant="outlined"
              color="inherit"
              onClick={handleDiscard}
            >
              Discard changes
            </StyledButton>
            <StyledButton
              disabled={!(changedContractFields.length > 0)}
              variant="outlined"
              color="primary"
              onClick={() => {
                setSaveDialog(true);
              }}
            >
              Save changes
            </StyledButton>
          </Grid>

          {/* Container with image and boolean fields */}
          <Grid item xs={12} md={4}>
            <BooleanContainer disabled={disabled} contract={contract} onChange={handleChange} />
          </Grid>

          {/* Container with general information */}
          <Grid item xs={12} md={8}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <GeneralInfoContainer
                disabled={disabled}
                contract={contract}
                onChange={handleChange}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
      </FormControl>

      {/* Save confirmation dialog */}
      <SaveDialog
        open={saveDialog}
        handleClose={() => setSaveDialog(false)}
        onSave={handleSave}
        changes={changedContractFields}
      />

      <Snackbar
        open={message.show}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        onClose={() => setMessage({ ...message, show: false })}
      >
        <Alert
          onClose={() => setMessage({ ...message, show: false })}
          variant="outlined"
          severity={message.type}
          sx={{ width: '100%', backgroundColor: 'white' }}
        >
          {message.message}
        </Alert>
      </Snackbar>
    </div>
  );
}
