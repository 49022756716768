
import { Autocomplete, CircularProgress, TextField } from '@material-ui/core';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { useCallback, useEffect, useState } from 'react';
import { getOffers } from '../../../../../api/elton-payments/subscriptions/offers';

export const SubscriptionAutoComplete = ({ onChange, label, id, disabled, useSimple }: any) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [selected, setSelected] = useState<any>(null);
  const [subscriptions, setSubscriptions]: any = useState<{ name: string; website: string }[]>([]);

  // Do Search after 600ms
  const fetchOperatorsDebounce = useCallback(AwesomeDebouncePromise(getOffers, 600), []);

  useEffect(() => {
    (async () => {
      // Only do update on change
      if (selected && selected?.id === id) {
        return;
      }

      // If a given id is provided load a specific operator, otherwise load first 25
      const result = (await getOffers(0, 50, 'name')).data.data;
      setSubscriptions(result);
      if (id && result) {
        const sub = result.find((c: any) => c.id === id);
        setSelected(sub);
      }
    })();
  }, [id, selected]);

  const handleUpdate = async (e: any) => {
    setLoading(true);
    const result = (
      await fetchOperatorsDebounce(0, 50, 'name', [{ type: 'name', value: e.target.value }])
    ).data.data;
    if (result) {
      setSubscriptions(result);
      setLoading(false);
    }
  };

  /**
   * Update state when user selects a given operator
   * @param value
   */
  const handleSelect = async (value: any) => {
    setSelected(value);
    onChange(value || {});
  };

  return (
    <Autocomplete
      fullWidth
      disabled={disabled}
      id={`${id}` || 'cont'}
      isOptionEqualToValue={(option: any, value: any) => option?.id === id}
      getOptionLabel={(option: any) => option.name}
      options={subscriptions}
      loading={loading}
      value={selected}
      filterOptions={(x) => x}
      onChange={(e: any, value: any) => handleSelect(value)}
      renderInput={(params) => (
        <TextField
          {...params}
          autoComplete="off"
          onChange={handleUpdate}
          label={useSimple ? '' : label || 'Country'}
          InputProps={{
            ...params.InputProps,
            autoComplete: 'new-password',
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            )
          }}
        />
      )}
    />
  );
};
