import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core';
import AddIcon from '@mui/icons-material/Add';
import { DataGrid, GridSortModel } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';

import { getPriceColumns } from './utils/gridColumns';
import { getOrder } from './utils/order';
import { ERROR } from '../../../../../theme/palette';
import { deletePrice } from '../../../../../api/elton-payments/operators';

export const PriceGrid = ({
  operator,
  tariff,
  setMessage,
  filters,
  disabled,
  editable,
  callbackUnlock,
  onDelete,
  setChangedDetails,
  setRowOperation,
  callbackSave,
  changed
}: any) => {
  // Sort and filters
  const [sortModel, setSortModel] = useState<GridSortModel>(getOrder('-created_at'));

  // Pages
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [open, setOpen] = useState(false);

  const [rowToDelete, setRowToDelete] = useState<Set<string>>(new Set());
  const [allPriceRows, setAllPriceRows] = useState<any[]>([]);
  const [totalRowCount, setTotalRowCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async (id: string) => {
    try {
      const row = new Set(rowToDelete);
      row.add(id);
      setRowToDelete(row);
      setRowOperation('DELETED', id);
      setMessage({
        show: true,
        message: 'Price row deleted.',
        type: 'success'
      });
    } catch (e) {
      setMessage({
        show: true,
        message: 'Unable to delete price row',
        type: 'error'
      });
    }
  };

  useEffect(() => {
    const newPrices: any[] = [];
    allPriceRows.forEach((price) => {
      if (!rowToDelete.has(price.id)) {
        newPrices.push(price);
      }
    });
    setAllPriceRows(newPrices);
  }, [rowToDelete]);

  /**
   * Update table on page or sortModel changes
   */
  useEffect(() => {
    const details = tariff.price_details.map((price: any) => ({
      ...price,
      id: price.primary_id,
      actions: {
        tariff_id: tariff.id,
        id: price.primary_id,
        canEdit: editable,
        deleteCallback: handleDelete
      }
    }));
    setAllPriceRows(details);
    setLoading(false);
    //fetchAndSetPrices();

  }, [tariff, page, pageSize, sortModel, editable]);

  /**
   * Update sort state, if value has changed
   * @param newModel
   */
  const handleSortModelChange = (newModel: GridSortModel) => {
    if (
      (newModel?.length === 0 && sortModel?.length !== 0) ||
      (newModel?.length !== 0 && sortModel?.length === 0)
    ) {
      setSortModel(newModel);
    } else if (newModel[0].field !== sortModel[0].field || newModel[0].sort !== sortModel[0].sort) {
      setSortModel(newModel);
    }
  };

  const handleDeletePrice = async () => {
    try {
      const response = await deletePrice(tariff.primary_id);
      handleClose();
      onDelete(crypto.randomUUID());
      setMessage({
        show: true,
        message: 'Price deleted successfully',
        type: 'success'
      });
    } catch (e) {
      setMessage({
        show: true,
        message: 'Unable to delete price',
        type: 'error'
      });
    }
  };

  const handleEdit = async (params: any) => {
    let fieldToUpdate = params.value === '' ? null : params.value;
    const pricesToSet: any[] = [];
    if (params.field == 'day_of_week' && params.value != null) {
      fieldToUpdate = fieldToUpdate.toUpperCase();
      fieldToUpdate = fieldToUpdate.replace(/\s/g, '');
      fieldToUpdate = fieldToUpdate.split(',');
    }
    allPriceRows.forEach((price: any) => {
      if (price.id === params.row.id) {
        let priceObject: any = {
          id: params.row.id,
          primary_id: params.row.id,
          external_index: params.row.external_index,
          startup_price: params.row.startup_price,
          energy_price: params.row.energy_price,
          energy_step_size: params.row.energy_step_size,
          time_price: params.row.time_price,
          time_step_size: params.row.time_step_size,
          parking_price: params.row.parking_price,
          parking_step_size: params.row.parking_step_size,
          day_of_week: params.row.day_of_week,
          restrictions: params.row.restrictions,
          actions: {
            tariff_id: tariff.id,
            id: params.row.id,
            canEdit: editable,
            deleteCallback: handleDelete
          },
          new_item: true
        };
        priceObject[params.field] = fieldToUpdate;
        pricesToSet.push(priceObject);
      } else {
        pricesToSet.push(price);
      }
    });
    setAllPriceRows(pricesToSet);
    setChangedDetails({
      ...changed,
      [params.row.id]: { ...params.row, [params.field]: fieldToUpdate }
    });

    setRowOperation('EDITED', params.row.id);
  };

  const handleCreate = async () => {
    try {
      const priceDetails = allPriceRows.map((price: any) => ({
        ...price,
        id: price.primary_id,
        actions: {
          tariff_id: tariff.id,
          id: price.primary_id,
          canEdit: editable,
          deleteCallback: handleDelete
        }
      }));
      const idCreate = crypto.randomUUID(); //Temporary id only used in frontend before save
      const newPrice = {
        id: idCreate,
        primary_id: idCreate,
        startup_price: 0,
        energy_price: 0,
        energy_step_size: 0,
        time_price: 0,
        time_step_size: 0,
        parking_price: 0,
        parking_step_size: 0,
        day_of_week: null,
        restrictions: null,
        actions: {
          tariff_id: tariff.id,
          id: idCreate,
          canEdit: editable,
          deleteCallback: handleDelete
        },
        new_item: true
      };

      priceDetails.push(newPrice);
      setRowOperation('ADDED', idCreate);
      setAllPriceRows(priceDetails);
      setMessage({
        show: true,
        message: 'New price row added.',
        type: 'success'
      });
    } catch (e) {
      setMessage({
        show: true,
        message: 'Unable to add price row',
        type: 'error'
      });
    }
  };

  return (
    <>
      <div>
        {editable && (
          <Button
            onClick={handleCreate}
            style={{
              width: '2rem',
              height: '2rem',
              minWidth: 0,
              float: 'right',
              position: 'absolute',
              right: '12.5rem',
              marginTop: '0.8rem',
              background: 'white',
              zIndex: 10000
            }}
            variant="outlined"
          >
            <AddIcon />
          </Button>
        )}
        <Button
          onClick={callbackUnlock}
          style={{
            width: '10rem',
            height: '2rem',
            minWidth: 0,
            float: 'right',
            position: 'absolute',
            right: '1.5rem',
            marginTop: '0.8rem',
            background: 'white',
            zIndex: 10000
          }}
          variant="outlined"
        >
          {editable ? 'Cancel' : 'Unlock editing'}
        </Button>
      </div>
      {editable && (
        <div>
          <Button
            onClick={callbackSave}
            style={{
              width: '40rem',
              height: '2rem',
              minWidth: 0,
              float: 'left',
              position: 'absolute',
              left: '1.5rem',
              marginTop: '20rem',
              background: 'white',
              zIndex: 10000
            }}
            variant="outlined"
          >
            Save all price changes
          </Button>

          <Button
            onClick={handleClickOpen}
            style={{
              width: '40rem',
              height: '2rem',
              minWidth: 0,
              float: 'right',
              position: 'absolute',
              right: '48.5rem',
              marginTop: '20rem',
              background: 'white',
              zIndex: 10000,
              borderColor: ERROR.light,
              color: ERROR.light
            }}
            variant="outlined"
          >
            Delete price
          </Button>
        </div>
      )}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Delete</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this price {tariff.primary_id}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="info" onClick={handleClose}>
            Cancel
          </Button>
          <Button color="success" onClick={handleDeletePrice} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <DataGrid
        rowHeight={60}
        disableColumnFilter
        disableSelectionOnClick
        onCellEditCommit={handleEdit}
        rows={allPriceRows}
        columns={getPriceColumns(operator)}
        pageSize={pageSize}
        page={page}
        pagination
        rowCount={totalRowCount}
        loading={loading}
        onPageSizeChange={(pageSize) => setPageSize(pageSize)}
        onPageChange={(newPage) => setPage(newPage)}
        rowsPerPageOptions={[5, 10, 25]}
        paginationMode="server"
        sortingMode="server"
        sortModel={sortModel}
        isCellEditable={(params) => editable}
        getRowClassName={(params) => {
          return params.row.external_index != 0 && allPriceRows.find((p) => p.external_index == 0)
            ? 'highlight'
            : '';
        }}
        sx={{
          '.highlight': {
            filter: 'opacity(50%)',
            '&:hover': {
              filter: 'opacity(50%)'
            }
          }
        }}
        components={{
          NoRowsOverlay: () => (
            <div
              style={{
                display: 'flex',
                marginTop: '10rem',
                justifyContent: 'center'
              }}
            >
              <span>
                <b>Unable to find any prices. Try creating a new one.</b>
              </span>
            </div>
          )
        }}
      />
    </>
  );
};
