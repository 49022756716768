// material
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField
} from '@material-ui/core';
import { DateRange, DateRangePicker, LocalizationProvider } from '@material-ui/lab';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useEffect, useState } from 'react';
import { Block } from '../../Block';
// ----------------------------------------------------------------------

export default function PickerDateRange({ callback, title, currencySelector, sentCompanies }: any) {
  const [value, setValue] = useState<DateRange<Date>>([null, null]);
  const [currency, setCurrency] = useState<string>('NOK');
  const [companies, setCompanies] = useState<string[]>(sentCompanies);
  const [company, setCompany] = useState<string>('');

  useEffect(() => {
    setCompanies(sentCompanies);
  }, [sentCompanies, companies]);
  return (
    <Stack spacing={3} direction={{ xs: 'column', md: 'row' }} style={{ marginBottom: '2em' }}>
      <Stack spacing={3} sx={{ width: 1 }}>
        <Block title={title}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateRangePicker
              startText="Start date (inclusive)"
              endText="Stop date (inclusive)"
              value={value}
              onChange={(newValue) => {
                setValue(newValue);
              }}
              renderInput={(startProps, endProps) => (
                <>
                  <TextField {...startProps} />
                  <Box sx={{ mx: 2 }}>to</Box>
                  <TextField {...endProps} />
                  {currencySelector ? (
                    <FormControl sx={{ m: 1, minWidth: 80 }}>
                      <InputLabel id="currency">Currency</InputLabel>
                      <Select
                        labelId="currency-selector"
                        id="currency-selector"
                        value={currency}
                        label="Currency"
                        onChange={(e) => {
                          setCurrency(e.target.value);
                          e.preventDefault();
                        }}
                      >
                        <MenuItem value="NOK">NOK</MenuItem>
                        <MenuItem value="SEK">SEK</MenuItem>
                        <MenuItem value="DKK">DKK</MenuItem>
                        <MenuItem value="EUR">EUR</MenuItem>
                      </Select>
                    </FormControl>
                  ) : (
                    ''
                  )}
                  {companies.length > 0 ? (
                    <FormControl sx={{ m: 1, minWidth: 150 }}>
                      <InputLabel id="company">Company</InputLabel>
                      <Select
                        labelId="currency-selector"
                        id="currency-selector"
                        value={company}
                        label="Company"
                        onChange={(e) => {
                          setCompany(e.target.value);
                          e.preventDefault();
                        }}
                      >
                        {companies.map((companyEach) => (
                          <MenuItem key={companyEach} value={companyEach}>
                            {companyEach}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  ) : (
                    ''
                  )}
                  <Button
                    style={{ marginLeft: '15px', height: '4em' }}
                    variant="contained"
                    color="success"
                    onClick={() => {
                      callback(value, currency, company);
                    }}
                  >
                    Search for report
                  </Button>
                </>
              )}
            />
          </LocalizationProvider>
        </Block>
      </Stack>
    </Stack>
  );
}
