// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------
export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
    revenues: path(ROOTS_DASHBOARD, '/revenues'),
    conversion: path(ROOTS_DASHBOARD, '/conversion'),
    charging: path(ROOTS_DASHBOARD, '/charging')
  },
  subscriptions: {
    offers: path(ROOTS_DASHBOARD, '/subscriptions/offers'),
    contracts: path(ROOTS_DASHBOARD, '/subscriptions/contracts'),
    transactions: path(ROOTS_DASHBOARD, '/subscriptions/transactions')
  },
  financials: {
    reports: path(ROOTS_DASHBOARD, '/financial/reports')
  },
  operators: path(ROOTS_DASHBOARD, '/operators'),
  countries: path(ROOTS_DASHBOARD, '/countries'),
  currencies: path(ROOTS_DASHBOARD, '/currencies'),
  user: {
    get: path(ROOTS_DASHBOARD, '/user/get')
  },
  loyalty: {
    user: path(ROOTS_DASHBOARD, '/loyalty/user'),
    tiers: path(ROOTS_DASHBOARD, '/loyalty/tiers'),
  },
  b2b: {
    companies: path(ROOTS_DASHBOARD, '/b2b/companies'),
    whitelisted: path(ROOTS_DASHBOARD, '/b2b/whitelisted'),
    weekly: path(ROOTS_DASHBOARD, '/b2b/report'),
    invoice: path(ROOTS_DASHBOARD, '/b2b/invoices'),
    plans: path(ROOTS_DASHBOARD, '/b2b/plans')
  },
  voucher: {
    companies: path(ROOTS_DASHBOARD, '/voucher/companies'),
    vouchers: path(ROOTS_DASHBOARD, '/voucher/users'),
    subscriptions: path(ROOTS_DASHBOARD, '/voucher/subscriptions')
  },
  partners: {
    partners: path(ROOTS_DASHBOARD, '/partners')
  },
  charging: {
    status: path(ROOTS_DASHBOARD, '/charging/status'),
    sessions: path(ROOTS_DASHBOARD, '/charging/sessions')
  },
  locations: {
    manage: {
      find: path(ROOTS_DASHBOARD, '/locations/find'),
      edit: path(ROOTS_DASHBOARD, '/locations/edit')
    },
    map: path(ROOTS_DASHBOARD, '/locations/map'),
    logs: {
      importJobs: path(ROOTS_DASHBOARD, '/locations/logs/import-jobs')
    }
  }
};
