import { getTransactions } from 'api/elton-payments/subscriptions/transactions';
import BasicGrid from 'components/grid/basic-grid';
import { TransactionsProps } from '../../../@types/subscriptions/transaction';
import { gridColumns } from './gridColumns';
import { getFilters } from './gridFilters';

export const Grid = (props: TransactionsProps) => {
  // Add feilds that is only used by the table
  const mapTransactions = async (...args: any) => {
    const result = await getTransactions(...args);
    result.data.data = result.data.data.map((t: any) => ({
      ...t,
      actions: t,
      firebase_id: t?.user?.uid
    }));
    return result;
  };

  return (
    <BasicGrid
      fetchFunction={mapTransactions}
      columns={gridColumns}
      pages={props.pages}
      filters={getFilters(props.filters)}
      order={props.order}
      onUpdate={props.onUpdate}
    />
  );
};
