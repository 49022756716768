import BasicGrid from 'components/grid/basic-grid';
import React from 'react';
import { getAllPlans } from '../../../api/elton-payments/charging/b2b';
import { UserContext } from '../../../contexts/UserContext';
import FormDialogs from './FormDialog';
import { gridColumns } from './gridColumns';

export const Grid = () => {
  const [{ user }]: any = React.useContext(UserContext);

  const getPlans = async () => {
    const localPlans = await getAllPlans().then((res) => {
      return res.map((plan) => {
        // @ts-ignore
        plan.id = plan.primary_id; // hack for basic grid, it needs unique `id`s
        return plan;
      });
    });
    return { data: { data: localPlans } };
  };

  return (
    <BasicGrid
      add={user.canEdit === true}
      form={<FormDialogs isEdit={false} />}
      fetchFunction={getPlans}
      columns={gridColumns}
      pages={{ page: 0, pageSize: 25 }}
      order={'created_at'}
    />
  );
};
