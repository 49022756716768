import { GridSortModel } from '@mui/x-data-grid';

export const getOrder = (order: string | null | undefined): GridSortModel => {
  if (order && order.length > 0) {
    if (order.charAt(0) === '-') {
      return [{ field: order.substring(1), sort: 'desc' }];
    }
    return [{ field: order, sort: 'asc' }];
  }
  // default order by newest
  return [{ field: 'created_at', sort: 'desc' }];
};
