import { Card, Typography } from '@material-ui/core';
import { useCallback, useEffect, useState } from 'react';
import { SwitchWithLabel } from './inputs/SwitchWithLabel';

export const BooleanContainer = ({ disabled, operator, onChange }: any) => {
  const [fields, setFields] = useState<any>({});

  useEffect(() => {
    setFields(operator);

  }, [operator]);

  const handleChange = useCallback(
    (value: any, field: string, displayName: string, displayValue: string) => {
      setFields({
        ...fields,
        [field]: value
      });
      onChange(value, field, displayName, displayValue);
    },
    [fields, onChange, setFields]
  );

  return (
    <Card sx={{ py: 4, px: 3 }} style={{ paddingBottom: '16.5rem' }}>
      <SwitchWithLabel
        disabled={disabled}
        value={fields?.only_use_base}
        title="Only use base prices?"
        type="only_use_base"
        onChange={handleChange}
        description="Should only base prices be used? Where external price id is not set"
      />

      <SwitchWithLabel
        disabled={disabled}
        value={fields?.can_charge_with_elton}
        title="Can charge with Elton?"
        type="can_charge_with_elton"
        onChange={handleChange}
        description="Can the operator be used to charge with Elton?"
      />

      <SwitchWithLabel
        disabled={disabled}
        value={fields?.app_scan_qr_code}
        title="Should the app use QR-scan as default?"
        type="app_scan_qr_code"
        onChange={handleChange}
        description='When pressing "Charge with Elton" should the user be redirected to QR-scan?'
      />

      <Typography style={{ marginTop: '1rem' }} variant="body2" sx={{ mb: 0.5 }}>
        * Marked values are not set.
      </Typography>
    </Card>
  );
};
