import "mapbox-gl/dist/mapbox-gl.css";
import LogOut from "pages/Logout";
import React from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { AuthWrapper } from "./components/AuthWrapper";
import RtlLayout from "./components/RtlLayout";
import ScrollToTop from "./components/ScrollToTop";
import ThemePrimaryColor from "./components/ThemePrimaryColor";
import ThemeConfig from "./theme";
import { KindeProvider } from "@kinde-oss/kinde-auth-react";
import { CONFIG } from "./config";

export default function App() {
    const navigate = useNavigate();
    return (
        <ThemeConfig>
            <ThemePrimaryColor>
                <RtlLayout>
                    <ScrollToTop />
                    <KindeProvider
                        clientId={CONFIG.KINDE.CLIENT_ID}
                        domain={CONFIG.KINDE.DOMAIN}
                        redirectUri={CONFIG.KINDE.REDIRECT_URI}
                        logoutUri={CONFIG.KINDE.LOGOUT_URI}
                        onRedirectCallback={(user, appState) => {
                            if (
                                appState === undefined ||
                                typeof appState.kindeOriginUrl !== "string"
                            )
                                return;

                            const url = new URL(appState.kindeOriginUrl);
                            navigate(url.pathname);
                        }}
                    >
                        <Routes>
                            <Route path="/*" element={<AuthWrapper />} />
                        </Routes>
                    </KindeProvider>
                </RtlLayout>
            </ThemePrimaryColor>
        </ThemeConfig>
    );
}
