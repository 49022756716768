import { Alert, Grid } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { Button, FormControl, Snackbar } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import {
  createCountryv2,
  getCountryv2,
  updateCountryv2
} from '../../../api/elton-payments/country';
import LoadingScreen from '../../LoadingScreen';
import { GeneralInfoContainer } from './GeneralInfoContainer';
import SaveDialog from './SaveDialog';
import { useNavigate } from 'react-router-dom';
import { createCurrency, updateCurrency } from '../../../api/elton-payments/currency';

const StyledButton = styled(Button)(({ theme }) => ({
  marginLeft: '1rem',
  height: '3rem',
  [theme.breakpoints.down('md')]: {
    margin: '2px',
    width: '100%'
  }
}));

export function Edit({ disabled, id, setId }: any) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  const [saveDialog, setSaveDialog] = useState<boolean>(false);
  const [country, setCountry] = useState<any>(null);
  const [message, setMessage] = useState<{
    show: boolean;
    message: string;
    type: 'success' | 'error';
  }>({
    show: false,
    message: '',
    type: 'success'
  });

  const [changedCountryFields, setChangedCountryFields] = useState<any[]>([]);

  const fetchCountry = useCallback(
    async (newId: string) => {
      if (newId) {
        setLoading(true);
        const result = await getCountryv2(newId);
        setCountry(result?.data.data);
        setChangedCountryFields([]);
        setLoading(false);
      } else {
        setLoading(false);
      }
    },
    [setCountry, setLoading]
  );

  /**
   * Fetch mew offer on id change
   */
  useEffect(() => {
    fetchCountry(id);
  }, [fetchCountry, id]);

  /**
   * Handle input change, and update changedFields
   * @param value
   * @param field
   * @param displayName
   * @param displayValue
   */
  const handleChange = useCallback(
    (value: any, field: string, displayName: string = '', displayValue: any = '') => {
      const change = { value, field, displayName, displayValue };
      setChangedCountryFields([...changedCountryFields.filter((l) => l.field !== field), change]);
    },
    [changedCountryFields, setChangedCountryFields]
  );

  const handleChangeMultiple = useCallback(
    (country: any) => {
      const changes = Object.keys(country).map((key) => ({ field: key, value: country[key] }));
      setChangedCountryFields(changedCountryFields.concat(changes));
    },
    [changedCountryFields, setChangedCountryFields]
  );

  /**
   * Reset state
   */
  const handleDiscard = () => {
    setCountry({ ...country });
    setChangedCountryFields([]);
  };

  /**
   * Save changedFields
   */
  const handleSave = async () => {
    // Close dialog
    setSaveDialog(false);

    // Change format to object
    const update: any = {};
    for (const change of changedCountryFields) {
      update[change.field] = change.value?.length === 0 ? 'null' : change.value;
    }

    try {
      if (id) {
        await updateCountryv2(country.primary_id, update);
      } else {
        const result = await createCountryv2(update);
        if (result.data.status == 'SUCCESS' && result.data.data.primary_id) {
          setId(result.data.data.primary_id);
          navigate(`/dashboard/countries/edit/${result.data.data.primary_id}`);
        }
      }
      // Reset view
      setChangedCountryFields([]);

      // Show Alert
      setMessage({ show: true, message: 'Country updated!', type: 'success' });

      // Fetch new offer
      await fetchCountry(id);
    } catch (error: any) {
      console.log(error);
      let message = 'Unknown Error';
      if (error?.status_message) {
        message = error.status_message;
      } else if (error?.message) {
        message = error?.message;
      }
      setMessage({ show: true, message, type: 'error' });
    }
  };

  return loading ? (
    <div style={{ height: 'calc(100vh - 320px)' }}>
      <LoadingScreen sx={{}} />
    </div>
  ) : (
    <div>
      <FormControl style={{ width: '100%' }}>
        <Grid container spacing={3}>
          {/* Search field to find offer */}
          <Grid item xs={12} md={4} />

          {/* Fake row to force new line */}
          <Grid style={{ display: 'flex', justifyContent: 'flex-end' }} item xs={12} md={8}>
            <StyledButton
              disabled={!(changedCountryFields.length > 0)}
              variant="outlined"
              color="inherit"
              onClick={handleDiscard}
            >
              Discard changes
            </StyledButton>
            <StyledButton
              disabled={!(changedCountryFields.length > 0)}
              variant="outlined"
              color="primary"
              onClick={() => {
                setSaveDialog(true);
              }}
            >
              Save changes
            </StyledButton>
          </Grid>

          {/* Container with general information */}
          <Grid item xs={12}>
            <GeneralInfoContainer
              disabled={disabled}
              country={country}
              onChange={handleChange}
              onChangeMany={handleChangeMultiple}
            />
          </Grid>
        </Grid>
      </FormControl>

      {/* Save confirmation dialog */}
      <SaveDialog
        open={saveDialog}
        handleClose={() => setSaveDialog(false)}
        onSave={handleSave}
        changes={changedCountryFields}
      />

      <Snackbar
        open={message.show}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        onClose={() => setMessage({ ...message, show: false })}
      >
        <Alert
          onClose={() => setMessage({ ...message, show: false })}
          variant="outlined"
          severity={message.type}
          sx={{ width: '100%', backgroundColor: 'white' }}
        >
          {message.message}
        </Alert>
      </Snackbar>
    </div>
  );
}
