import { BasicGridFilter, BasicGridTransformedFilters } from '../../../@types/grid';

export const getFilters = (filters: BasicGridTransformedFilters): BasicGridFilter[] => [
  {
    type: 'user_id',
    displayName: 'User Id',
    value: filters?.user_id || '',
    icon: 'ant-design:user-outlined'
  },
  {
    type: 'contract_id',
    displayName: 'Contract Id',
    value: filters?.contract_id || '',
    icon: 'carbon:document'
  }
];
