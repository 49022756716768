import BasicGrid from 'components/grid/basic-grid';
import { WeeklyProps } from '../../../@types/b2b/b2b';
import { getWeekly } from '../../../api/elton-payments/charging/transactions';
import { gridColumns } from './gridColumns';
import { getFilters } from './gridFilters';

export const Grid = (props: WeeklyProps) => {
  const mapWeekly = async (...args: any[]) => {
    const result = await getWeekly(
      props.date_from,
      props.date_to,
      props?.primary?.id,
      props?.company_name,
      null, // args[0]
      null, // args[1]
      ...args.slice(2)
    );
    result.data.data = result.data.data.map((weekly: any) => ({
      ...weekly,
      id: weekly.transaction_id,
      // description is just for the export
      customer_name: weekly.company_name,
      // ugly formatting, but it's being force by prettier or w/e
      description:
        new Date(weekly.start_time).toLocaleString('no-NB') +
        ' - ' +
        new Date(weekly.stop_time).toLocaleString('no-NB') +
        ' | ' +
        weekly.user.toString() +
        ' | ' +
        weekly.operator.toString() +
        ' | ' +
        weekly.energy.toString() +
        'kWh' +
        (weekly.reference && weekly.reference !== '' ? ' | ' + weekly.reference : ''),
      sales_price: weekly.total_cost,
      currency_export: weekly.currency,
      country: weekly.country
    }));

    return result;
  };

  return (
    <div>
      <BasicGrid
        key={props.date_from.toISOString()}
        add={false}
        fetchFunction={mapWeekly}
        columns={gridColumns}
        pages={props.pages}
        filters={getFilters(props.filters)}
        order={props.order}
        onUpdate={props.onUpdate}
        exportFields={['customer_name', 'description', 'currency_export', 'sales_price', 'country']}
      />
    </div>
  );
};
