import { Pagination, Stack } from '@material-ui/core';
import { styled } from '@material-ui/system';
import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { getTariffs } from '../../../../api/elton-payments/operators';
import { Filters } from './Filters';
import { TariffCard } from './tariff-card/TariffCard';

const ButtonContainer = styled('div')({
  width: '100%',
  padding: '2rem 0 1rem 0',
  '& > button': { height: '3rem' }
});

/**
 * Container for all Tariffs
 * One tariffcard might have one or more price rows
 * @returns
 */
export const Prices = ({ operatorId, setMessage, operator, setLoading, disabled }: any) => {
  const [priceElements, setPricesElements] = useState<any>([]);
  const [addedPriceElement, setAddedPriceElement] = useState<any>([]);
  const [filters, setFilters] = useState<any>({});

  const [deletedState, setDeletedState] = useState<string>('');

  const [page, setPage] = useState<number>(1);
  const [pageCount, setPageCount] = useState<number>(1);

  const setElements = async (options: any = { order_by: 'created_at' }) => {
    let currentPage = page;
    if (
      filters.external_price_id &&
      options.external_price_id &&
      filters.external_price_id !== options.external_price_id
    ) {
      currentPage = 1;
      setPage(1);
    }
    setFilters(options);
    const result = await getTariffs(operatorId, currentPage, 5, options);

    setPageCount(result.data.last_page);
    setPricesElements(result.data.data);
    setLoading(false);
  };

  useEffect(() => {
    if (operatorId) {
      setElements();
    }
  }, [operatorId, deletedState, page]);

  /**
   * Create new Restirction
   */
  const createNewRestriction = async () => {
    const prices = priceElements.map((price: any) => ({
      ...price,
      new_item: false
    }));

    const idCreate = crypto.randomUUID(); //Temporary id only used in frontend before save

    prices.push({
      id: idCreate,
      primary_id: idCreate,
      external_price_id: null,
      start_datetime: null,
      end_datetime: null,
      region: null,
      from_power: null,
      to_power: null,
      price_details: [],
      operator: null,
      new_item: true
    });
    setPricesElements(prices);
  };

  return (
    <div>
      <Filters setElements={setElements} />
      {priceElements.map((e: any) => (
        <TariffCard
          setDeletedState={setDeletedState}
          disabled={disabled}
          key={e.primary_id}
          tariff={e}
          filters={filters}
          setMessage={setMessage}
          operator={operator}
        />
      ))}
      <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '2rem' }}>
        <Stack spacing={2}>
          <Pagination
            page={page}
            count={pageCount}
            onChange={(e, value) => {
              setPage(value);
              window.scrollTo(0, 500);
            }}
            color="primary"
          />
        </Stack>
      </div>
      <ButtonContainer>
        {!disabled && (
          <Button onClick={createNewRestriction} variant="outlined" color="primary" fullWidth>
            Create New Tariff Restriction
          </Button>
        )}
      </ButtonContainer>
    </div>
  );
};
